import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import style from './style.module.less';
import LogoImage from '../../assets/logo.png';

interface Props {
  isMenuOpen?: boolean;

}

export const LogoImageComponent: FC<Props> = (props) => {
  const { isMenuOpen } = props;
  
  return (
    <Link to='/' className={`${style.logo} ${isMenuOpen ? style.hide : ''}`}>
      <img src={LogoImage} alt='AskMaxwell' className={style.logoImage}/>
    </Link>
  );
};
