export const formatDate = (dateOrTimestamp: Date | number, locale?: string) => {
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(dateOrTimestamp);
};

export const formatTime = (dateOrTimestamp: Date | number, locale?: string) => {
  return new Intl.DateTimeFormat(locale, {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(dateOrTimestamp);
};

export const formatDateTime = (
  dateOrTimestamp: Date | number,
  locale?: string
) => {
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(dateOrTimestamp);
};

export const formatMediumDateTime = (
  dateOrTimestamp: Date | number,
  locale?: string
) => {
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(dateOrTimestamp);
};