import React, { FC, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../utils/format-date';
import { LegalCaseState, Prematter } from '@law-connect/types';
import style from './style.module.less';
import FolderIcon from '../../../assets/icons/folder.svg?react';
import AuthModal, { LoginModalRef } from '../../../components/auth-modal';
import { useAppDispatch } from '../../../redux/hooks';
import { actions } from '../../../redux/slices';
import {
  NextStepCard,
} from '../../../components/next-step-card';
import { useMobile } from '../../../hooks/use-is-mobile';

interface Props {
  prematter: Prematter;
  readOnly?: boolean;
}

const CompletedSummaryComponent: FC<Props> = (props) => {
  const { prematter } = props;
  const navigate = useNavigate();
  const { isAuthenticated } = useKindeAuth();
  const authRef = useRef<LoginModalRef>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(undefined, { lng: prematter.language });

  const isMobile = useMobile();

  const [questionnaire, setQuestionnaire] = React.useState<boolean>(false);

  useEffect(() => {
    if (
      prematter?.context.extendedForm &&
      prematter?.context.extendedForm.data &&
      !prematter?.context.extendedForm.complete &&
      !questionnaire
    ) {
      setQuestionnaire(true);
    }
  }, [prematter?.context.extendedForm, questionnaire]);

  const onComplete = () => {
    // The following line is commented out because it is
    // now run server-side after submitting the questionnaire
    // dispatch(actions.session.process());
    if (isAuthenticated) {
      const navigateTo = `/case/${prematter?.id}`;
      dispatch(actions.session.delete());
      navigate(navigateTo);
    } else {
      authRef.current.openModal();
    }
  };

  const showAuth = useMemo(
    () => prematter.state === LegalCaseState.AuthCheck,
    [prematter.state]
  );

  const textMessage = useMemo(() => {
    if (showAuth) {
      return t('chat.complete-form-no-auth');
    } else {
      return t('chat.complete-form-auth');
    }
  }, [showAuth, t]);

  return (
    <>
      <AuthModal ref={authRef} redirectTo={`/case/${prematter.id}`} />
      <div className={style.container}>
        <div className={style.textMessage}>{textMessage}</div>
      </div>
      <NextStepCard
        image={{
          src: `/next-steps/report${isMobile ? '-mobile' : ''}.png`,
          icon: <FolderIcon width={24} height={24} />,
          label: 'Report',
        }}
        title={prematter?.name}
        features={[formatDate(prematter.createdAt)]}
        button={{
          label: t('chat.view-answers'),
          onClick: onComplete,
        }}
        className={style.nextStepCard}
        rounded={true}
      />
    </>
  );
};

export default CompletedSummaryComponent;
