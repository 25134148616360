import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import LoadingSvg from '../../assets/icons/loading.svg?react';
import { useAppSelector } from '../../redux/hooks';
import selectors from '../../redux/selectors';
import { LegalCaseState, MessageType, Prematter } from '@law-connect/types';
import { isPrematterComplete } from '../../utils/is-prematter-complete';

interface Props {
  prematter?: Prematter;
}

const ChatLoadingComponent: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { prematter } = props;

  const [label, setLabel] = useState<string | undefined>(undefined);

  const isConnecting = useAppSelector(selectors.websocket.isConnecting());
  const isSessionPending = useAppSelector(selectors.session.isSessionPending());
  const isFetchSessionPending = useAppSelector(
    selectors.session.isFetchSessionPending()
  );
  const isSendFormPending = useAppSelector(
    selectors.session.isSendFormPending()
  );
  const isLocationPending = useAppSelector(
    selectors.session.isLocationPending()
  );
  const loading: boolean = useMemo(() => {
    return (
      isConnecting ||
      isSessionPending ||
      false ||
      (prematter?.context?.extendedForm ? false : isSendFormPending)
    );
  }, [
    isConnecting,
    isSessionPending,
    prematter?.context?.extendedForm,
    isSendFormPending,
  ]);
  const loadingRef = useRef<HTMLDivElement>(null);

  const getLoadingLabel = useCallback(
    (state: LegalCaseState) => {
      if (isConnecting || isFetchSessionPending) {
        return undefined;
      }

      if (isSendFormPending) {
        return t('chat.loading.submitting');
      }
      if (isPrematterComplete(prematter.state, true)) {
        return undefined;
      }

      switch (state) {
        case LegalCaseState.Location:
          if (
            isLocationPending ||
            (prematter?.messages || []).findIndex(
              (m) => m.type === MessageType.Location
            ) > -1
          ) {
            return t('chat.loading.location');
          }
          return 'typing';
        case LegalCaseState.Questions:
          if (prematter?.context?.questions?.length) {
            return t('chat.loading.form');
          }
          return t('chat.loading.questions');
        case LegalCaseState.Form:
          return t('chat.loading.form');
        case LegalCaseState.AuthCheck:
          return t('chat.loading.auth');
        case LegalCaseState.PreparingReport:
          return t('chat.loading.preparing');
        default:
          return 'typing';
      }
    },
    [
      isConnecting,
      isFetchSessionPending,
      isLocationPending,
      isSendFormPending,
      prematter?.context?.questions?.length,
      prematter?.messages,
      prematter?.state,
      t,
    ]
  );

  useEffect(() => {
    if (prematter?.state) {
      const theLabel = getLoadingLabel(prematter?.state);
      if (theLabel === 'typing') {
        setLabel(t('chat.loading.analyzing'));
        const timeout = setTimeout(() => {
          setLabel(t('chat.loading.typing'));
        }, 1000);
        return () => {
          clearTimeout(timeout);
        };
      } else {
        setLabel(theLabel);
      }
    }
  }, [getLoadingLabel, prematter?.state, t]);

  return loading ? (
    <div className={style.loadingSpacer} ref={loadingRef}>
      <LoadingSvg className={style.loadingMessageIcon} />
      {label ? <div className={style.label}>{label}</div> : null}
    </div>
  ) : null;
};

export default ChatLoadingComponent;
