import { PayloadAction, createListenerMiddleware } from '@reduxjs/toolkit';
import { actions } from '../slices';
import api from '../api';
const FAILURE_RETRY = 5;
const DELAY_COUNT = 2000;

// long poll to server to fetch updated messages based off connectionId
let activeId: string | null = null;
const lawyerMessageMiddleware = createListenerMiddleware();

lawyerMessageMiddleware.startListening({
  actionCreator: actions.lawyerConnection.pollMessages,
  effect: async (
    action: PayloadAction<{
      connectionId: string;
      init?: boolean;
      errorCount?: number;
    }>,
    listenerApi
  ) => {
    const { connectionId, init } = action.payload;
    // if it is the first call we want to set the activeId
    if (init) {
      console.log('set activeId - ', connectionId);
      activeId = connectionId;
    }
    if (connectionId !== activeId) {
      console.error(
        `Connection id does not match active id ${connectionId} !== ${activeId}`
      );
      // we have either moved onto a new connection or the connection has been closed
      return;
    }
    // we want to continously call server to get new messages for the connection that is set up
    try {
      const newMessage = await api.lawyerConnection.longPoll({ connectionId });
      // we only want to add the message if it is from the lawyer for now
      // TODO: if multi tab user chat we might have issues but worry about it then
      if (newMessage && newMessage.from === 'lawyer') {
        listenerApi.dispatch(
          actions.lawyerConnection.addMessage({
            connectionId,
            message: newMessage,
          })
        );
      }
      // we want to cycle through the messages as long as the connection is active
      if (activeId === connectionId) {
        listenerApi.dispatch(
          actions.lawyerConnection.pollMessages({ connectionId })
        );
      } else {
        console.error(`Connection id does not match active id ${
          connectionId
        } !== ${activeId}`);
      }
    } catch (error) {
      // if for some reason the connection is closed we want to fetch all the messages and retry polling up to X times
      if (
        action.payload.errorCount &&
        action.payload.errorCount > FAILURE_RETRY
      ) {
        console.error('Failed to poll messages after multiple retries');
        return;
      } else {
        // delay X+1 * 2 seconds before retrying
        await new Promise((resolve) =>
          setTimeout(
            resolve,
            ((action.payload.errorCount ?? 0) + 1) * DELAY_COUNT
          )
        );
        listenerApi.dispatch(
          actions.lawyerConnection.fetchMessages({ id: connectionId })
        );
        listenerApi.dispatch(
          actions.lawyerConnection.pollMessages({
            connectionId,
            errorCount: action.payload.errorCount
              ? action.payload.errorCount + 1
              : 1,
          })
        );
      }
    }
  },
});

lawyerMessageMiddleware.startListening({
  actionCreator: actions.lawyerConnection.stopPollMessages,
  effect: async (
    action: PayloadAction<{
      connectionId: string;
    }>,
    listenerApi
  ) => {
    const { connectionId } = action.payload;
    // if it is the first call we want to set the activeId
    if (connectionId === activeId) {
      activeId = null;
    }
  },
});

export default lawyerMessageMiddleware;
