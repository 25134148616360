import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import TickCircleIcon from '../../../assets/icons/tick-circle.svg?react';
import ArrowLeftIcon from '../../../assets/icons/arrow-left.svg?react';
import { getLanguage, languages, setLanguage } from '../../../i18n';
import useStopScroll from '../../../hooks/use-stop-scroll';
import { MENU_CONTENT_ID, MENU_HEADER_ID } from '../../header';
import { LogoImageComponent } from '../../header/logo';
import { CloseIconComponent } from '../../header/close';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  closeMenu: () => void;
}

export const DropdownComponent: React.FC<Props> = (props) => {
  const { isOpen, closeModal, closeMenu } = props;
  const [selectedLanguage, setSelectedLanguage] = useState(getLanguage());
  const { t } = useTranslation();
  const closeTimer = useRef<number | null>(null);
  const [inContentMenu, setInContentMenu] = useState(false);
  const [inDropdown, setInDropdown] = useState(false);

  useStopScroll(isOpen);

  const sortedLanguagesList = useMemo(
    () =>
      Object.entries(languages).sort(([_a, languageA], [_b, languageB]) =>
        languageA.nativeName.localeCompare(languageB.nativeName)
      ),
    []
  );

  const changeLanguage = useCallback(
    (code: string) => {
      if (code !== selectedLanguage) {
        setLanguage(code);
        setSelectedLanguage(code);
        closeMenu();
      }
    },
    [selectedLanguage, closeMenu]
  );

  const mouseEnterContent = useCallback(() => {
    setInContentMenu(true);
  }, []);
  const mouseLeaveContent = useCallback(() => {
    setInContentMenu(false);
  }, []);
  const mouseEnterDropdown = useCallback(() => {
    setInDropdown(true);
  }, []);
  const mouseLeaveDropdown = useCallback(() => {
    setInDropdown(false);
  }, []);

  useEffect(() => {
    if (closeTimer.current) {
      clearTimeout(closeTimer.current);
    }

    const checkClose = () => {
      if (!inContentMenu && !inDropdown) {
        closeModal();
      }
    };
    closeTimer.current = window.setTimeout(() => checkClose(), 200);
    return () => checkClose();
  }, [closeModal, inContentMenu, inDropdown]);

  const menuHeaderRef = document.getElementById(MENU_CONTENT_ID);

  useEffect(() => {
    // to the menu header ref we want to attatch the mouse enter and leave events
    if (menuHeaderRef) {
      menuHeaderRef.addEventListener('mouseleave', mouseLeaveContent);
      menuHeaderRef.addEventListener('mouseenter', mouseEnterContent);
    }
    return () => {
      if (menuHeaderRef) {
        menuHeaderRef.removeEventListener('mouseleave', mouseLeaveContent);
        menuHeaderRef.removeEventListener('mouseenter', mouseEnterContent);
      }
    };
  }, [menuHeaderRef, mouseEnterContent, mouseLeaveContent]);
  
  return menuHeaderRef ? (
    <>
      {createPortal(
        <div
          className={`${style.dropdownContainer} ${isOpen ? style.isOpen : ''}`}
          onMouseEnter={mouseEnterDropdown}
          onMouseLeave={mouseLeaveDropdown}
        >
          {/* <Header /> */}
          <div className={style.wrapper}>
            <div className={style.mobileHeader}>
              <div className={style.arrowIconContainer} onClick={closeModal}>
                <ArrowLeftIcon className={style.arrowIcon} />
              </div>
              <LogoImageComponent />
              <CloseIconComponent closeMenu={closeMenu} isMenuOpen={isOpen} />
            </div>
            <div className={style.header}>{t('language.select-language')}</div>
            <div className={style.languageSelector}>
              {sortedLanguagesList.map(([code, language]) => {
                const selected = code === selectedLanguage;
                const onClick = () => changeLanguage(code);
                return (
                  <div
                    key={language.name}
                    className={`${style.languageSelectorItem} ${
                      selected ? style.selected : ''
                    }`}
                    onClick={onClick}
                  >
                    {language.nativeName}
                    {selected && (
                      <div className={style.tickCircleWrapper}>
                        <TickCircleIcon className={style.tickCircle} />
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>,
        document.getElementById(MENU_HEADER_ID)
      )}

      {isOpen &&
        createPortal(
          <div className={style.headerOverlay} />,
          document.getElementById('root')
        )}
    </>
  ) : null;
};
