import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import selectors from '../../../redux/selectors';
import {
  NarrowWrapper,
  WrapperOffsetSide,
} from '../../../components/narrow-wrapper';
import { useTranslation } from 'react-i18next';
import { Question } from '@law-connect/types';
import styles from './style.module.less';
import { Footer } from '../../../components/footer';
import { actions } from '../../../redux/slices';
import { SectionedForm } from '@law-connect/react-components';
import { LogoImageComponent } from '../../../components/header/logo';
import { BackHeader } from '../../../components/back-header';
import PencilIcon from '../../../assets/icons/pencil.svg?react';
import { toast } from 'react-toastify';

interface Props {}
export const LawyerConnectionClientIntakeForm: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const form = useAppSelector(
    selectors.lawyerConnection.getClientIntakeForm(id)
  );

  const onChange = (questions: Question[]) => {
    dispatch(actions.lawyerConnection.updateClientIntakeForm({
      connectionId: id,
      form: {
        ...form,
        questions,
      },
    }));
  };

  const onSubmit = async (questions: Question[]) => {
    // We are assuming that the onChange has already been called & the form server-side is the latest
    try {
      await dispatch(actions.lawyerConnection.markClientIntakeFormAsCompleted({
        connectionId: id,
      })).unwrap();
      toast.success('Submitted successfully');
      navigate(`/lawyer-connection/${id}`);
    } catch (error) {
      console.error('submit client intake form', error);
      toast.error('Failed to send. Please try again.');
    }
  };

  useEffect(() => {
    if (!form) {
      dispatch(
        actions.lawyerConnection.fetchClientIntakeForm({ connectionId: id })
      );
    }
  }, [form]);

  if (!form) {
    return null;
  }

  return (
    <div className={styles.clientIntakeFormWrapper}>
      <div className={styles.clientIntakeFormContent}>
        <div className={styles.menu}>
          <LogoImageComponent />
        </div>
        <div className={styles.header}>
          <BackHeader />
          <div className={styles.icon}>
            <PencilIcon />
          </div>
          <div className={styles.title}>Questionnaire</div>
          <div className={styles.description}>
            You have been asked to fill out the questionnaire in as much detail
            as possible. You can save your progress and come back later. You may
            edit as many times as you like until you submit. Once submitted, you
            will not be able to edit information.
          </div>
        </div>
        <div className={styles.clientIntakeFormBody}>
          <NarrowWrapper
            offset={WrapperOffsetSide.LEFT}
            offsetClassName={styles.fullWidth}
          >
            <SectionedForm
              title='Categories'
              form={form}
              onChange={onChange}
              onSubmit={onSubmit}
              onSubmitLabel='Submit questionnaire'
              disabled={isNaN(form.completedAt)}
            />
          </NarrowWrapper>
        </div>
        <Footer />
      </div>
    </div>
  );
};
