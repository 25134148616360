import { Prematter } from '@law-connect/types';
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardPlaceholder } from '../../components/card';
import { getIcon } from './get-icon';
import { getTitle } from './use-get-title';
import { useNavigate } from 'react-router-dom';
import style from './style.module.less';
import { isPrematterComplete } from '../../utils/is-prematter-complete';
import { Carousel, CarouselRef } from '@law-connect/react-components';
import { useScreenSize, WidthType } from '../../hooks/use-is-mobile';
import AddIcon from '../../assets/icons/add.svg?react';

interface Props {
  prematters: Prematter[];
}

export const RecentCardsComponent: FC<Props> = (props) => {
  const { prematters } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cardsScrollRef = useRef<CarouselRef>(null);
  const [expectedCards, setExpectedCards] = useState<number | null>(0);

  const screenType = useScreenSize();
  const cardWidth = useMemo(() => {
    if (screenType === WidthType.Mobile) {
      return 250;
    } else if (screenType === WidthType.LargeDesktop) {
      return 385;
    } else {
      return 328;
    }
  }, [screenType]);

  const getTitleWrapper = useCallback(
    (prematter: Prematter) => {
      return getTitle(t, prematter);
    },
    [t]
  );

  const startNewChat = useCallback(() => {
    navigate('/chat', { state: { newCase: true } });
  }, [navigate]);

  const noCases = useMemo(() => prematters.length === 0, [prematters?.length]);
  const handleCardResize = useCallback(() => {
    const getExpectedCards = cardsScrollRef.current?.getExpectedCards();
    if (getExpectedCards != null) {
      setExpectedCards(getExpectedCards);
    }
  }, []);

  useEffect(() => {
    handleCardResize();
    window.addEventListener('resize', handleCardResize);
    return () => window.removeEventListener('resize', handleCardResize);
  }, [handleCardResize]);

  const cardBody = useMemo(() => {
    if (noCases) {
      return [...Array(expectedCards)].map((_, index) => (
        <CardPlaceholder
          key={index}
          className={style.placeholderCard}
          placeholder
        />
      ));
    } else {
      return [
        <CardPlaceholder
          onClick={startNewChat}
          className={style.placeholderCard}
          key='new-case'
        >
          <AddIcon className={style.addIcon} />
          {t('account.start-new-case')}
        </CardPlaceholder>,
        ...prematters.map((prematter, index) => (
          <Card
            id={index}
            key={prematter.id}
            icon={getIcon(prematter.state)}
            title={getTitleWrapper(prematter)}
            timestamp={prematter.updatedAt}
            buttonLabel={
              isPrematterComplete(prematter.state)
                ? t('account.view')
                : t('account.edit')
            }
            onClick={() => {
              isPrematterComplete(prematter.state)
                ? navigate(`/case/${prematter.id}`)
                : navigate(`/case/${prematter.id}/chat`);
            }}
          />
        )),
      ];
    }
  }, [
    noCases,
    expectedCards,
    startNewChat,
    t,
    prematters,
    getTitleWrapper,
    navigate,
  ]);

  return (
    <div className={style.carouselContainer}>
      <Carousel
        ref={cardsScrollRef}
        cardWidth={cardWidth}
        showProgressBar={noCases != true}
        items={cardBody}
        classNames={{
          cardsContainer: style.cardsContainer,
          cardsPosition: style.cardsPosition,
        }}
      />
    </div>
  );
};
