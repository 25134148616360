import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import style from './style.module.less';
import { Wrapper } from '../../components/wrapper';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import { useTranslation } from 'react-i18next';
import { Link, Route, Routes, useNavigate } from 'react-router-dom';
import { Button } from '../../components/button';
import HomeCustomer from '../../assets/home-customer.png';
import FullHomeCustomer from '../../assets/home-customer-full.png';
// eslint-disable-next-line @stylistic/js/max-len
import { useScreenSize, WidthType } from '../../hooks/use-is-mobile';
import { HomeHero } from './hero';
import { useHomeExamples } from '../../hooks/use-home-examples';
import RefreshIcon from '../../assets/icons/refresh.svg?react';
import { HomeDescriptionComponent } from './description';
import { Helmet } from 'react-helmet';

const GENERATE_AMOUNT = 4;

interface Props {}
export const Home: React.FC<Props> = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register, isAuthenticated } = useKindeAuth();
  const screenType = useScreenSize();
  const homeExamples = useHomeExamples();

  const [examples, setExamples] = useState(
    [...homeExamples].sort(() => Math.random() - 0.5)
  );
  const [page, setPage] = useState(0);

  useEffect(() => {
    setExamples([...homeExamples].sort(() => Math.random() - 0.5));
  }, [homeExamples]);

  const onCaseClick = useCallback(
    (description?: string | null) => {
      navigate('/chat', {
        state: { newCase: true, message: description ?? undefined },
      });
    },
    [navigate]
  );

  const onAuthClick = useCallback(() => {
    if (isAuthenticated) {
      navigate('/chat', {
        state: { newCase: true, message: undefined },
      });
    } else {
      register();
    }
  }, [isAuthenticated, navigate, register]);

  const regenerate = useCallback(() => {
    if (page < Math.ceil(examples.length / GENERATE_AMOUNT) - 1) {
      setPage(page + 1);
    } else {
      setPage(0);
    }
  }, [examples.length, page]);

  const homeCustomerImage = useMemo(
    () =>
      screenType === WidthType.LargeDesktop ||
      screenType === WidthType.SmallDesktop
        ? HomeCustomer
        : FullHomeCustomer,
    [screenType]
  );

  return (
    <>
      <Helmet><title>{t('pages.home')}</title></Helmet>
      <Header />
      <Wrapper className={style.wrapper} contentClassName={style.content}>
        <HomeHero />
        <HomeDescriptionComponent />
        <div className={style.examplesSection}>
          <div className={style.title}>{t('home.example-title')}</div>
          <div className={style.examplesContainer}>
            {examples
              .slice(page * GENERATE_AMOUNT, (page + 1) * GENERATE_AMOUNT)
              .map((example) => (
                <div
                  className={style.row}
                  key={example.description}
                  onClick={() => onCaseClick(example.description)}
                >
                  {/* <CompletePrematterIcon className={style.icon} /> */}
                  <div className={style.iconContainer}>
                    <example.icon className={style.icon} />
                  </div>
                  <div className={style.textWrapper}>
                    <div className={style.exampleTitle}>{example.title}</div>
                    <div className={style.exampleDescription}>
                      {example.description}
                    </div>
                  </div>
                  <div className={style.actionContainer}>
                    <Button
                      label={t('home.try-it-out')}
                      className={style.actionButton}
                      labelClassName={style.buttonLabel}
                      iconClassName={style.buttonIcon}
                      onClick={() => {}}
                    />
                  </div>
                </div>
              ))}
            <div className={style.tryMoreContainer}>
              <button className={style.tryMoreButton} onClick={regenerate}>
                <span>{t('home.try-some-more')}</span>
                <RefreshIcon className={style.refreshIcon} />
              </button>
            </div>
          </div>
        </div>
        <div className={style.section}>
          <div className={style.homeCustomerContainer}>
            <img
              src={homeCustomerImage}
              alt='home-customer'
              className={style.image}
            />
          </div>
          <div className={`${style.cardContainer} ${style.right}`}>
            <div className={style.bodyWrapper}>
              <div className={style.title}>{t('home.title2')}</div>
              <Button
                label={
                  isAuthenticated ? t('home.start-a-case') : t('home.sign-up')
                }
                className={style.startButton}
                labelClassName={style.buttonLabel}
                iconClassName={style.buttonIcon}
                onClick={onAuthClick}
              />
            </div>
          </div>
        </div>
        <div className={`${style.section} ${style.center}`}>
          <div
            className={style.disclaimer}
            dangerouslySetInnerHTML={{ __html: t('auth.disclaimer') }}
          />
        </div>
      </Wrapper>
      <Footer />
    </>
  );
};
