import React from 'react';
import { useTranslation } from 'react-i18next';
import Markdown from 'react-markdown';
import style from './style.module.less';

interface Props {
  children: string;
}

export const ReportSummary: React.FC<Props> = (props) => {
  const { children } = props;
  const { t } = useTranslation();
  return (
    <div className={style.reportSummary}>
      <div className={style.title}>{t('prematter.conclusion')}</div>
      <div className={style.description}>
        <Markdown className={style.markdown}>{children}</Markdown>
      </div>
    </div>
  );
};
