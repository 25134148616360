import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { actions } from '../../redux/slices';
import selectors from '../../redux/selectors';
import { confirmModal } from '../../components/confirm-modal';

import BinIcon from '../../assets/icons/bin.svg?react';
import RefreshIcon from '../../assets/icons/refresh.svg?react';
import { ChatRef } from '../chat';
import { SessionExpiryWarning } from '../../components/session-expiry';
import { usePrevious } from '../../hooks/use-previous';
import { ChatWrapperComponent } from '../../components/chat-wrapper';
import { SHOW_CHAT_BOX } from '../chat/chatbox/constants';

export const AnonymousChat = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useKindeAuth();
  const [message, setMessage] = useState<string>('');
  // 30 min session expiry
  const [sessionExpiresAt, setSessionExpiresAt] = useState(
    Date.now() + 25 * 60 * 1000
  );
  const chatHistoryRef = useRef<ChatRef>();
  const [disabled, setDisabled] = useState<boolean>(false);

  const prematterId = useAppSelector(selectors.session.getPrematterId());
  const sessionId = useAppSelector(selectors.session.getSessionId());
  const sendingMessage = useAppSelector(selectors.websocket.isSendingMessage());
  const isDeletingPrematter = useAppSelector(
    selectors.prematter.isDeletePending()
  );
  const prevDeletePending = usePrevious(isDeletingPrematter);
  const deleteError = useAppSelector(selectors.prematter.getDeleteError());
  const isDeletingSession = useAppSelector(
    selectors.session.isDeleteSessionPending()
  );
  const prematter = useAppSelector(selectors.session.getPrematter());
  const isInChat = useMemo(
    () => SHOW_CHAT_BOX.includes(prematter?.state),
    [prematter?.state]
  );

  useEffect(() => {
    if (!isDeletingPrematter && prevDeletePending && !deleteError) {
      navigate('/account');
    }
  }, [deleteError, isDeletingPrematter, navigate, prevDeletePending]);

  useEffect(() => {
    if (sendingMessage) {
      setSessionExpiresAt(Date.now() + 30 * 60 * 1000);
    }
  }, [sendingMessage]);

  useEffect(() => {
    if (location?.state?.newCase) {
      // End the websocket connection
      dispatch(actions.websocket.deleteSession());
      // Clear the current session (this will also start a new one)
      dispatch(actions.session.delete());
      if (location?.state?.message) {
        setMessage(location?.state?.message);
      }
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location, dispatch, navigate]);

  const deleteChat = useCallback(() => {
    dispatch(actions.session.delete());
    if (isAuthenticated) {
      dispatch(actions.prematter.delete({ id: prematterId }));
    } else {
      navigate('/');
    }
  }, [dispatch, isAuthenticated, prematterId, navigate]);

  const getBounding = useCallback(() => {
    return chatHistoryRef.current?.getChatBoundingClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatHistoryRef.current]);

  const resetChat = useCallback(() => {
    setMessage('');
    dispatch(actions.session.delete());
    dispatch(actions.websocket.deleteSession());
    setSessionExpiresAt(Date.now() + 30 * 60 * 1000);
    if (disabled) {
      setDisabled(false);
    }
    navigate(location.pathname, { replace: true, state: {} });
  }, [disabled, dispatch, location.pathname, navigate]);

  const refreshSession = useCallback(() => {
    dispatch(actions.session.fetch());
    setSessionExpiresAt(Date.now() + 30 * 60 * 1000);
    if (disabled) {
      setDisabled(false);
    }
  }, [disabled, dispatch]);

  const disableChat = () => {
    setDisabled(true);
  };

  useEffect(() => {
    // on load if prematterId exists we also fetch files
    if (prematterId && sessionId && !location?.state?.newCase) {
      dispatch(actions.file.fetchByPrematterId({ prematterId }));
    }
  }, [dispatch, prematterId, sessionId, location]);

  return (
    <>
      {!isAuthenticated && sessionExpiresAt - Date.now() <= 5 * 60 * 1000 && (
        <SessionExpiryWarning
          resetSession={resetChat}
          refreshSession={refreshSession}
          disableChat={disableChat}
        />
      )}
      <ChatWrapperComponent
        getBounding={getBounding}
        isLoading={isDeletingSession}
        message={message}
        isInChat={isInChat}
        chatHistoryRef={chatHistoryRef}
        backItems={[
          {
            label: t('confirm.reset'),
            onClick: () =>
              confirmModal({
                title: t('confirm.reset'),
                description: <>{t('account.delete-case-confirmation')}</>,
                action: {
                  label: t('confirm.yes'),
                  onClick: () => resetChat(),
                },
                small: true,
              }),
            icon: <RefreshIcon />,
          },
          {
            label: t('confirm.delete'),
            onClick: () =>
              confirmModal({
                title: t('confirm.delete'),
                description: <>{t('account.delete-case-confirmation')}</>,
                action: {
                  label: t('confirm.yes'),
                  onClick: () => deleteChat(),
                },
                small: true,
              }),
            icon: <BinIcon />,
          },
        ]}
      />
    </>
  );
};
