import React, { createRef, useEffect } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import selectors from '../../redux/selectors';
import { actions } from '../../redux/slices';
import { PrematterReport } from './report';
import { PrematterHistory } from './history';
import { PrematterChat } from './chat';
import { PrematterInfo } from './info';
import {
  FormSource,
  IntroductoryFormType,
  LegalCaseState,
} from '@law-connect/types';
import { PrematterFiles } from './files';
import { PreparingReportComponent } from './preparing';
import { NotFoundComponent } from '../not-found';
import { useCookieHandler } from '../../utils/cookie';
import { SHOW_DISCLAIMER_COOKIE } from '../../constants';
import {
  DisclaimerModal,
  DisclaimerModalRef,
} from '../../components/disclaimer-modal';
import { usePrevious } from '../../hooks/use-previous';
import { PrematterReportPlus } from './report-plus';
import { isPrematterComplete } from '../../utils/is-prematter-complete';
import { ConnectLawyers } from './connect';

interface Props {}
export const PrematterRouter: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const disclaimerRef = createRef<DisclaimerModalRef>();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const prematter = useAppSelector(selectors.prematter.getById(id));
  const prevPrematter = usePrevious(prematter);
  const fetchByIdPending = useAppSelector(
    selectors.prematter.isFetchByIdPending()
  );
  const prevFetchByIdPending = usePrevious(fetchByIdPending);
  const fetchByIdPrematterError = useAppSelector(
    selectors.prematter.getFetchByIdError()
  );

  // cookie for showing disclaimer modal
  const cookieHandler = useCookieHandler();
  const showDisclaimer = cookieHandler.getCookie(SHOW_DISCLAIMER_COOKIE);
  const isDeletingPrematter = useAppSelector(
    selectors.prematter.isDeletePending()
  );
  const prevDeletePending = usePrevious(isDeletingPrematter);
  const deleteError = useAppSelector(selectors.prematter.getDeleteError());

  useEffect(() => {
    if (!isDeletingPrematter && prevDeletePending && !deleteError) {
      navigate('/account');
    }
  }, [deleteError, isDeletingPrematter, navigate, prevDeletePending]);

  useEffect(() => {
    dispatch(actions.prematter.fetchById({ id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (
      !fetchByIdPending &&
      prevFetchByIdPending &&
      !prematter &&
      !fetchByIdPrematterError
    ) {
      navigate('/not-found');
    }
  }, [
    fetchByIdPending,
    fetchByIdPrematterError,
    navigate,
    prematter,
    prevFetchByIdPending,
  ]);

  useEffect(() => {
    if (
      disclaimerRef?.current &&
      showDisclaimer !== 'false' &&
      isPrematterComplete(prematter?.state, true)
    ) {
      disclaimerRef.current.openModal();
    }
  }, [disclaimerRef, prematter?.state, showDisclaimer]);

  useEffect(() => {
    dispatch(actions.file.fetchByPrematterId({ prematterId: id }));
  }, [id, dispatch]);

  const onClose = () => {
    cookieHandler.setCookie(SHOW_DISCLAIMER_COOKIE, 'false');
  };
  // if we have the prematter, and the prematter is in the preparing report state, we want to check if the prematter can be verified
  useEffect(() => {
    if (
      prematter &&
      prematter.state === LegalCaseState.ReportReady &&
      (prematter.id !== prevPrematter?.id ||
        prematter.state !== prevPrematter?.state)
    ) {
      dispatch(actions.prematter.canVerify({ id: prematter.id }));
    }
  }, [dispatch, prematter, prevPrematter?.id, prevPrematter?.state]);

  useEffect(() => {
    if (
      prematter &&
      prematter.state === LegalCaseState.Verified &&
      (prematter.id !== prevPrematter?.id ||
        prematter.state !== prevPrematter?.state)
    ) {
      dispatch(
        actions.lawyerConnection.fetchByPrematterId({
          prematterId: prematter.id,
        })
      );
    }
  }, [dispatch, prematter, prevPrematter?.id, prevPrematter?.state]);

  if (!prematter) {
    return null;
  }

  if (prematter?.state === LegalCaseState.PreparingReport) {
    return <PreparingReportComponent prematter={prematter} />;
  }

  return (
    <>
      <Routes>
        <Route path='/' element={<PrematterReport />} />
        <Route path='/history/*' element={<PrematterHistory />} />
        <Route path='/info' element={<PrematterInfo />} />
        <Route path='/files' element={<PrematterFiles />} />
        <Route path='/chat' element={<PrematterChat />} />
        <Route path='/connect' element={<ConnectLawyers />} />
        {prematter?.language === 'en' &&
        prematter?.context?.form?.source === IntroductoryFormType.Divorce ? (
            <Route path='/questionnaire/*' element={<PrematterReportPlus />} />
          ) : null}
        <Route path='*' element={<NotFoundComponent />} />
      </Routes>
      <DisclaimerModal ref={disclaimerRef} onClose={onClose} />
    </>
  );
};
