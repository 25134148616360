import React from 'react';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import {
  NarrowWrapper,
  WrapperOffsetSide,
} from '../../components/narrow-wrapper';
import { Link } from 'react-router-dom';
import style from './style.module.less';
import { PageTitleHero } from '../../components/page-title-hero';
import { ContentParagraphs } from '../../components/content-paragraphs';
import { useTranslation } from 'react-i18next';
import { INFO_LINK } from '../../constants';
import { Helmet } from 'react-helmet';

interface Props {}
export const Terms: React.FC<Props> = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('pages.terms')}</title>
      </Helmet>
      <Header />
      <PageTitleHero title={t('terms.title')} />
      <NarrowWrapper
        offset={WrapperOffsetSide.LEFT}
        offsetClassName={style.offsetContainer}
      >
        <div className={style.content}>
          <div className={style.section}>
            <ContentParagraphs className={style.paragraph}>
              <p>
                {t('terms.description.0')}
                <br />
                {t('terms.description.1')}
              </p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>1. {t('terms.1.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('terms.1.body')}</p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>2. {t('terms.2.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <ul className={style.list}>
                <li>
                  <strong>{t('terms.2.body.0.label')}:</strong>{' '}
                  {t('terms.2.body.0.text')}
                </li>

                <li>
                  <strong>{t('terms.2.body.1.label')}:</strong>{' '}
                  {t('terms.2.body.1.text')}
                </li>
                <li>
                  <strong>{t('terms.2.body.2.label')}:</strong>{' '}
                  {t('terms.2.body.2.text')}
                </li>
              </ul>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>3. {t('terms.3.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <ul className={style.list}>
                <li>
                  <strong>{t('terms.3.body.0.label')}:</strong>{' '}
                  {t('terms.3.body.0.text')}
                </li>

                <li>
                  <strong>{t('terms.3.body.1.label')}:</strong>{' '}
                  {t('terms.3.body.1.text')}
                </li>
                <li>
                  <strong>{t('terms.3.body.2.label')}:</strong>{' '}
                  {t('terms.3.body.2.text')}
                </li>
              </ul>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>4. {t('terms.4.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <ul className={style.list}>
                <li>
                  <strong>{t('terms.4.body.0.label')}:</strong>{' '}
                  {t('terms.4.body.0.text')}
                </li>
                <li>
                  <strong>{t('terms.4.body.1.label')}:</strong>{' '}
                  {t('terms.4.body.1.text')}
                </li>
              </ul>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>5. {t('terms.5.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p dangerouslySetInnerHTML={{ __html: t('terms.5.body') }} />
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>6. {t('terms.6.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('terms.6.body')}</p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>7. {t('terms.7.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('terms.7.body')}</p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>8. {t('terms.8.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('terms.8.body')}</p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>9. {t('terms.9.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('terms.9.body')}</p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>10. {t('terms.10.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <ul className={style.list}>
                <li>
                  <strong>{t('terms.10.body.0.label')}:</strong>{' '}
                  {t('terms.10.body.0.text')}
                </li>
                <li>
                  <strong>{t('terms.10.body.1.label')}:</strong>{' '}
                  {t('terms.10.body.1.text')}
                </li>
                <li>
                  <strong>{t('terms.10.body.2.label')}:</strong>{' '}
                  {t('terms.10.body.2.text')}
                </li>
              </ul>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>11. {t('terms.11.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>{t('terms.11.body')}</p>
            </ContentParagraphs>
          </div>

          <div className={style.section}>
            <h2>{t('terms.contact.title')}</h2>
            <ContentParagraphs className={style.paragraph}>
              <p>
                {t('terms.contact.body')}{' '}
                <Link to={`mailto:${INFO_LINK}`}>{INFO_LINK}</Link>
              </p>
            </ContentParagraphs>
          </div>
        </div>
      </NarrowWrapper>
      <Footer />
    </>
  );
};
