import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { toast } from 'react-toastify';
import i18n from 'i18next';

const STORE = 'user';
const prematterThunk = {
  fetch: createAsyncThunk(`${STORE}/fetch`, async (_, { rejectWithValue }) => {
    try {
      const response = await api.user.fetch();
      return response;
    } catch (error) {
      console.error('fetch user', error);
      return rejectWithValue(error.message as string);
    }
  }),
  delete: createAsyncThunk(
    `${STORE}/delete`,
    async (_, { rejectWithValue }) => {
      try {
        const response = await api.user.delete();
        toast.success(i18n.t('toast.delete-user-success'));
        return response;
      } catch (error) {
        toast.error(i18n.t('toast.delete-error'));
        return rejectWithValue(error.message as string);
      }
    }
  ),
  update: createAsyncThunk(
    `${STORE}/update`,
    async (
      user: { firstName?: string; lastName?: string },
      { rejectWithValue }
    ) => {
      try {
        const response = await api.user.update(user);
        toast.success(i18n.t('toast.update-user-success'));
        return response;
      } catch (error) {
        toast.error(i18n.t('toast.update-error'));
        return rejectWithValue(error.message as string);
      }
    }
  ),
};

export default prematterThunk;
