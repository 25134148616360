import { IntroductoryFormType, Prematter } from '@law-connect/types';
import { env } from '../constants/env';

const variants = ['A'];

function hashString(str: string): number {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}
function getVariant(userId: string): string {
  const hash = hashString(userId);
  const variantIndex = hash % variants.length;
  return variants[variantIndex];
}

export const getVariantFromUserId = (userId: string): string => {
  return getVariant(userId);
};

export const getVariantFromTimestamp = (timestamp: number): string => {
  const variantIndex = Math.floor(timestamp / 1000) % variants.length;
  return variants[variantIndex];
};

export const getVariantFromPrematter = (prematter: Prematter): string => {
  if (prematter?.userId) {
    return getVariantFromUserId(prematter.userId);
  }

  if (prematter?.context?.form && env.PUBLIC_URL !== 'https://askmaxwell.com') {
    // This is purely for testing purposes.
    const includesSecretSauceA =
      prematter.context.form.source === IntroductoryFormType.Divorce &&
      prematter.language === 'en' &&
      prematter.messages.find((m) => m.content.toString().includes('secret sauce A')) &&
      variants.includes('A');

    const includesSecretSauceB =
      prematter.context.form.source === IntroductoryFormType.Divorce &&
      prematter.language === 'en' &&
      prematter.messages.find((m) => m.content.toString().includes('secret sauce B')) &&
      variants.includes('B');

    if (includesSecretSauceA) {
      return 'A';
    } else if (includesSecretSauceB) {
      return 'B';
    }
  }

  return getVariantFromTimestamp(prematter.createdAt);
};
