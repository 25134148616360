import React, {
  ChangeEvent,
  FC,
  RefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 as uuid } from 'uuid';
import {
  LegalCaseState,
  WebSocketMessage,
  WebSocketMessageType,
} from '@law-connect/types';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import selectors from '../../../redux/selectors';
import { actions } from '../../../redux/slices';
import style from './style.module.less';
import ReCAPTCHA from 'react-google-recaptcha';
import { load } from '@fingerprintjs/botd';
import AuthModal, {
  AuthModalType,
  LoginModalRef,
} from '../../../components/auth-modal';
import { WidthType, useScreenSize } from '../../../hooks/use-is-mobile';
import { SHOW_CHAT_BOX } from './constants';
import { ChatInputComponent } from '../input';
import { usePrevious } from '../../../hooks/use-previous';
import { env } from '../../../constants/env';
import { COOKIE_ID } from '../../../components/cookie-consent';
import { useHandleChatResize } from './use-handle-chat-resize';

const botdPromise = load({
  // We disable monitoring so that the botd library doesn't send user identifiable data to the server
  monitoring: false,
});

interface Props {
  initialMessage?: string;
  getChatBoundingClient?: () => DOMRect;
  disabled?: boolean;
  chatBodyRef?: RefObject<HTMLDivElement>;
  scrollToEnd?: () => void;
}

export const ChatboxComponent: FC<Props> = (props) => {
  const {
    initialMessage,
    getChatBoundingClient,
    disabled = false,
    chatBodyRef,
    scrollToEnd,
  } = props;
  const { isAuthenticated } = useKindeAuth();
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const screenType = useScreenSize();
  const dispatch = useAppDispatch();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const authModalRef = useRef<LoginModalRef | null>(null);
  const overlapDetectionRef = useRef<HTMLDivElement>(null);
  const chatRef = useRef<HTMLDivElement>(null);

  const [message, setMessage] = useState('');
  const [files, setFiles] = useState<File[]>([]);
  const [textAreaHeight, setTextAreaHeight] = useState<number>(0);
  const [cookieHeight, setCookieHeight] = useState<number>(0);
  const prevTextAreaHeight = usePrevious(textAreaHeight);
  const prevCookieHeight = usePrevious(cookieHeight);

  const chatState = useAppSelector(selectors.session.getChatState());
  const isInChat = useMemo(
    () => SHOW_CHAT_BOX.includes(chatState),
    [chatState]
  );
  const isSendingMessage = useAppSelector(
    selectors.websocket.isSendingMessage()
  );
  const isWsConecting = useAppSelector(selectors.websocket.isConnecting());
  const isWsConnected = useAppSelector(selectors.websocket.isSetUp());
  const prematterId = useAppSelector(selectors.session.getPrematterId());
  const isUploadPendingList = useAppSelector(selectors.file.isUploadPending());
  const tempUploadFiles = useAppSelector(selectors.file.getTempFiles());
  const isFileUploading = useMemo(
    () => Object.values(isUploadPendingList ?? {}).some((v) => v),
    [isUploadPendingList]
  );
  const prevFileUploading = usePrevious(isFileUploading);

  const isDeletingSession = useAppSelector(
    selectors.session.isDeleteSessionPending()
  );

  const isWaitingForServer = useAppSelector(
    selectors.session.isWaitingForServer()
  );
  const isFetchSessionPending = useAppSelector(
    selectors.session.isFetchSessionPending()
  );
  const isGetFormPending = useAppSelector(selectors.session.isGetFormPending());
  const isSendFormPending = useAppSelector(
    selectors.session.isSendFormPending()
  );
  const isLocationPending = useAppSelector(
    selectors.session.isLocationPending()
  );
  const loading: boolean = useMemo(() => {
    return (
      isWsConecting ||
      isWaitingForServer ||
      isFetchSessionPending ||
      isGetFormPending ||
      isSendFormPending ||
      isLocationPending
    );
  }, [
    isWsConecting,
    isWaitingForServer,
    isFetchSessionPending,
    isGetFormPending,
    isSendFormPending,
    isLocationPending,
  ]);
  const tempMessage = useAppSelector(selectors.session.getTempMessage());
  const chatDisabled = useMemo(() => {
    return disabled || loading || isSendingMessage || isFileUploading;
  }, [disabled, loading, isSendingMessage, isFileUploading]);
  const prevChatDisabled = usePrevious(chatDisabled);

  const sendMessage = useCallback(
    async (data: WebSocketMessage) => {
      if (
        chatState === LegalCaseState.Location ||
        chatState === LegalCaseState.MatterTypes ||
        !chatState
      ) {
        if (!isWsConnected) {
          if (!isWsConecting) {
            let captcha: string;
            if (!isAuthenticated) {
              try {
                const result = await botdPromise
                  .then((botd) => botd.detect())
                  .then((result) => result)
                  .catch((e) => {
                    return { bot: false };
                  });
                if (result.bot) {
                  authModalRef.current.openModal();
                  return;
                }
                if (recaptchaRef.current) {
                  captcha = await recaptchaRef.current.executeAsync();
                }
              } catch (e) {
                console.error(e);
              }
            }
            // Send captcha to websocket
            dispatch(
              actions.websocket.connect({
                prematterId,
                captcha,
              })
            );
          }
          // wait 0.5 sec for websocket to be connected
          await new Promise((resolve) => setTimeout(resolve, 500));
        }
        if (!isSendingMessage) {
          dispatch(actions.websocket.sendMessage(data));
        }
      }
    },
    [
      isSendingMessage,
      chatState,
      isWsConnected,
      dispatch,
      isWsConecting,
      isAuthenticated,
      prematterId,
    ]
  );

  const onSend = useCallback(async () => {
    if (!(disabled || loading)) {
      if (files.length) {
        files.forEach((file) => {
          dispatch(
            actions.file.upload({
              file,
              attachedToMessage: true,
            })
          );
        });
        setFiles([]);
        // we want to add the temp message here to the chat
        if (message) {
          dispatch(actions.session.addTempMessage(message));
          setMessage('');
        }
      } else if (message.length && !isSendingMessage) {
        sendMessage({
          id: uuid(),
          type: WebSocketMessageType.Chat,
          from: 'user',
          message: message.trim(),
          timestamp: Date.now(),
        });
        setMessage('');
      }
    }
  }, [
    disabled,
    loading,
    files,
    message,
    isSendingMessage,
    dispatch,
    sendMessage,
  ]);

  const onChangeInput = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  }, []);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        if (event.shiftKey === false) {
          event.preventDefault();
          onSend();
        }
      }
    };
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [onSend]);

  // if in chat we want to set the cookie header to not fixed
  useEffect(() => {
    const cookieConsent = document.getElementById(COOKIE_ID);
    if (cookieConsent) {
      cookieConsent.className = `${cookieConsent.className} inChat`;
    }

    return () => {
      if (cookieConsent) {
        cookieConsent.className = cookieConsent.className.replace('inChat', '');
      }
    };
  }, []);

  useEffect(() => {
    if (initialMessage && !isDeletingSession) {
      sendMessage({
        id: uuid(),
        type: WebSocketMessageType.Chat,
        from: 'user',
        message: initialMessage.trim(),
        timestamp: Date.now(),
      });
    }
    // We ignore because we only send once if exists
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialMessage, isDeletingSession]);

  // the following effects are only used by desktop to create a sticky input field
  const scrollHandler = useCallback(() => {
    if (getChatBoundingClient) {
      const chatPosition = getChatBoundingClient();
      const overlapBounding =
        overlapDetectionRef.current?.getBoundingClientRect();
      if (chatPosition && overlapBounding) {
        // means the chat is above the overlap
        if (chatPosition.bottom <= overlapBounding.bottom) {
          chatRef.current?.classList.add(style.fixChat);
          chatRef.current?.classList.remove(style.floating);
        } else {
          chatRef.current?.classList.remove(style.fixChat);
          chatRef.current?.classList.add(style.floating);
        }
      }
    }
  }, [getChatBoundingClient]);

  useEffect(() => {
    const removeEventListener = () => {
      window.removeEventListener('scroll', scrollHandler);
      window.removeEventListener('resize', scrollHandler);
      chatRef.current?.classList.remove(style.fixChat);
      chatRef.current?.classList.remove(style.floating);
    };
    scrollHandler();
    window.addEventListener('scroll', scrollHandler);
    window.addEventListener('resize', scrollHandler);

    return () => {
      removeEventListener();
    };
  }, [scrollHandler]);

  // this is for handling files being uploaded
  // after file is uploaded we want to attatch the message to the chat
  useEffect(() => {
    if (
      isInChat &&
      !isFileUploading &&
      prevFileUploading &&
      tempUploadFiles.length
    ) {
      setFiles([]);
      sendMessage({
        id: uuid(),
        type: WebSocketMessageType.Chat,
        from: 'user',
        message: tempMessage ?? '',
        timestamp: Date.now(),
        fileIds: tempUploadFiles.map((file) => file.id),
      });
      dispatch(actions.file.clearTemp());
      dispatch(actions.session.clearTempMessage());
      // need to finish sending message first before fetching files
    }
  }, [
    isInChat,
    isFileUploading,
    prevFileUploading,
    tempMessage,
    sendMessage,
    tempUploadFiles,
    dispatch,
    prematterId,
  ]);

  const setFilesWrapper = (fileList: File[]) => {
    // Filter out duplicates in the fileList
    const newFiles = fileList.filter(
      (file, index, self) =>
        index === self.findIndex((t) => t.name === file.name)
    );

    setFiles(newFiles);
  };

  // handle blur and focus after sending message
  useLayoutEffect(() => {
    if (isInChat && textareaRef.current) {
      if (chatDisabled && !prevChatDisabled) {
        textareaRef.current.blur();
      } else if (!chatDisabled && prevChatDisabled) {
        textareaRef.current.focus();
      }
    }
  }, [chatDisabled, isInChat, isSendingMessage, prevChatDisabled]);

  // handle resizing of chat input
  const calcHeightResize = useCallback(
    (noCookie?: boolean) => {
      if (textareaRef.current) {
        textareaRef.current.style.height = '0px';
        const scrollHeight = textareaRef.current.scrollHeight;
        textareaRef.current.style.height = scrollHeight + 'px';
        setTextAreaHeight(scrollHeight);

        if (chatBodyRef?.current) {
          // we also want to check if the cookie consent is present and if it is we want to make the chat window body smaller
          const cookieConsent = document.getElementById(COOKIE_ID);
          let ch = 0;
          if (cookieConsent?.getBoundingClientRect()?.height && !noCookie) {
            ch = cookieConsent.getBoundingClientRect().height;
            setCookieHeight(ch);
          } else if (noCookie || cookieHeight) {
            setCookieHeight(0);
          }
          chatBodyRef.current.style.minHeight = `calc(100vh - 
          ${chatRef.current?.clientHeight}px - ${ch}px)`;
        }
      }
    },
    [chatBodyRef, chatRef, cookieHeight]
  );

  useEffect(() => {
    // textarea height or cookieHeight changes scroll to bottom
    if (
      textAreaHeight !== prevTextAreaHeight ||
      cookieHeight !== prevCookieHeight
    ) {
      scrollToEnd();
    }
  }, [
    scrollToEnd,
    textAreaHeight,
    cookieHeight,
    prevTextAreaHeight,
    prevCookieHeight,
  ]);

  useHandleChatResize({
    calcHeightResize,
    files,
    message,
  });

  const InputBody = (
    <ChatInputComponent
      message={message}
      onChangeInput={onChangeInput}
      onSend={onSend}
      disabled={chatDisabled}
      ref={textareaRef}
      files={files}
      setFiles={setFilesWrapper}
      className={style.inputContainer}
    />
  );

  return !isInChat ? null : (
    <>
      {screenType !== WidthType.Mobile ? (
        <>
          <div
            className={`${style.overlapWrapper} ${style.fixChat}`}
            ref={chatRef}
            style={
              { '--cookie-height': `${cookieHeight}px` } as React.CSSProperties
            }
          >
            {InputBody}
            <div className={style.placeholder} />
          </div>
          <div
            ref={overlapDetectionRef}
            className={style.overlapDetectionRef}
          />
        </>
      ) : (
        InputBody
      )}
      {!isAuthenticated ? (
        <>
          <ReCAPTCHA
            ref={recaptchaRef}
            size='invisible'
            sitekey={env.RECAPTCHA_SITE_KEY}
          />
          <AuthModal ref={authModalRef} type={AuthModalType.BOT_AUTH} />
        </>
      ) : null}
    </>
  );
};

export default ChatboxComponent;
