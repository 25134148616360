import React, { useEffect } from 'react';
import { Header } from '../../../components/header';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hooks';
import selectors from '../../../redux/selectors';
import { PrematterHeader } from '../../../components/prematter-header';
import { useTranslation } from 'react-i18next';
import {
  NarrowWrapper,
  WrapperOffsetSide,
} from '../../../components/narrow-wrapper';
import { NoPaddingContentTitle } from '../../../components/content-title';
import { ChatHistory } from '../../../components/chat-history';
import style from './style.module.less';
import { Footer } from '../../../components/footer';

interface Props {}
export const PrematterHistory: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();

  const { t } = useTranslation();
  const prematter = useAppSelector(selectors.prematter.getById(id));

  useEffect(() => {
    // scroll to top on load
    window.scrollTo(0, 0);
  }, []);

  if (!prematter) {
    return null;
  }

  return (
    <>
      <Header />
      <div className={style.wrapper}>
        <PrematterHeader prematter={prematter} />
        <NarrowWrapper
          backgroundClassName={style.chatWrapper}
          contentClassName={style.chatContent}
          offset={WrapperOffsetSide.LEFT}
          offsetClassName={style.tabletWidth}
        >
          <NoPaddingContentTitle
            title={t('prematter.case-history')}
            className={style.title}
          />
          <ChatHistory prematter={prematter} />
        </NarrowWrapper>
        <div className={style.leftBackground} />
      </div>
      <Footer />
    </>
  );
};
