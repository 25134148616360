/* eslint-disable @stylistic/js/max-len */
import FaceBookIcon from '../assets/icons/social-fb.svg?react';
import XIcon from '../assets/icons/social-x.svg?react';
import { WebSocketMessageType } from '@law-connect/types';
import env from './env';

export const ALLOWED_MESSAGE_TYPES: WebSocketMessageType[] = [
  WebSocketMessageType.Chat,
  WebSocketMessageType.Location,
  WebSocketMessageType.Confirmation,
];

export const WINDOW_REQUEST_RESET_TOKEN = 'reset-token';
export const FALLBACK_LANGUAGE = 'en';

export const CONTACT_US_LINK = `mailto:support@${env.PUBLIC_URL.replace(/^https?:\/\//, '')}`;
export const INFO_LINK = `info@${env.PUBLIC_URL.replace(/^https?:\/\//, '')}`;
export const PRIVACY_LINK = `privacy@${env.PUBLIC_URL.replace(/^https?:\/\//, '')}`;
export const PORTAL_CHAT_MOBILE = 'portal-chat-mobile';

export const GOOGLE_PRIVACY_POLICY = 'https://policies.google.com/privacy';
export const GOOGLE_TERMS = 'https://policies.google.com/terms';
export const SHOW_DISCLAIMER_COOKIE = 'show-disclaimer';
export const MAX_ALLOWED_QUESTION = 10;
export const AUTH_MODAL_PORTAL_ID = 'auth-modal-portal';

export const SOCIAL_MEDIA_LINKS = [
  {
    icon: FaceBookIcon,
    link: 'https://www.facebook.com/AskMaxwellAI',
  },
  {
    icon: XIcon,
    link: 'https://x.com/askmaxwellai',
  }
];