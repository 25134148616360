import loadEnv from '../../constants/env';
import { security } from './security';

export const enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

enum ServerResponseStatus {
  Success = 'success',
  Failure = 'failure',
}

const request = {
  raw: async (
    path: string,
    method: RequestMethod,
    body?: string | FormData
  ): Promise<unknown> => {
    let token = '';
    try {
      token = await security.getAccessToken();
    } catch (e) {
      // do nothing, no token available.
    }

    const headers: HeadersInit = {};

    if (typeof body === 'string') {
      headers['Content-Type'] = 'application/json';
    }

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const response = await fetch(
      `${loadEnv.SERVER_ENPOINT || '/api'}${path}`,
      {
        credentials: 'include',
        headers,
        method,
        body,
      }
    );

    const getJson = async () => {
      try {
        const json = await response.json();
        return json;
      } catch (e) {
        return {};
      }
    };
    if (response.status === 200) {
      return getJson();
    } else {
      if (response.status >= 400) {
        try {
          const json = await getJson();

          // we still want to fetch the error message
          return {
            status: ServerResponseStatus.Failure,
            message: json.message ?? json.error ?? 'Request failed.',
            timestamp: Date.now(),
          };
        } catch (error) {
          return {
            status: ServerResponseStatus.Failure,
            message: 'Request failed.',
            timestamp: Date.now(),
          };
        }
      }
      if (response.status === 401) {
        // should never get here since kinde handles this
      }
      return getJson();
    }
  },
  get: (path: string): Promise<unknown> => request.raw(path, RequestMethod.GET),
  post: (path: string, body = {}): Promise<unknown> =>
    request.raw(path, RequestMethod.POST, JSON.stringify(body)),
  put: (path: string, body = {}): Promise<unknown> =>
    request.raw(path, RequestMethod.PUT, JSON.stringify(body)),
  patch: (path: string, body = {}): Promise<unknown> =>
    request.raw(path, RequestMethod.PATCH, JSON.stringify(body)),
  delete: (path: string, body = {}): Promise<unknown> =>
    request.raw(path, RequestMethod.DELETE, JSON.stringify(body)),
  multipart: {
    post: (path: string, body: FormData): Promise<unknown> =>
      request.raw(path, RequestMethod.POST, body),
    put: (path: string, body: FormData): Promise<unknown> =>
      request.raw(path, RequestMethod.PUT, body),
  },
};

export default request;
