import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { ClientIntakeForm } from '@law-connect/types';

const STORE = 'lawyerConnection';
const lawyerConnectionThunk = {
  fetchAll: createAsyncThunk(
    `${STORE}/fetchAll`,
    async (_, { rejectWithValue }) => {
      try {
        const response = await api.lawyerConnection.fetchAll();
        return response;
      } catch (error) {
        console.error('fetch connections', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  fetchByPrematterId: createAsyncThunk(
    `${STORE}/fetchByPrematterId`,
    async (args: { prematterId: string }, { rejectWithValue }) => {
      try {
        const { prematterId } = args;
        const response = await api.lawyerConnection.fetchByPrematter({
          id: prematterId,
        });
        return response;
      } catch (error) {
        console.error('fetch connections by prematter', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  fetchById: createAsyncThunk(
    `${STORE}/fetchById`,
    async (args: { id: string }, { rejectWithValue }) => {
      try {
        const { id } = args;
        const response = await api.lawyerConnection.fetchById({ id });
        return response;
      } catch (error) {
        console.error('fetch connection by id', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  fetchMessages: createAsyncThunk(
    `${STORE}/fetchMessages`,
    async (args: { id: string }, { rejectWithValue }) => {
      try {
        const { id } = args;
        const response = await api.lawyerConnection.fetchMessages({ id });
        return response;
      } catch (error) {
        console.error('fetch message by id', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  sendMessage: createAsyncThunk(
    `${STORE}/sendMessage`,
    async (args: { id: string; message: string }, { rejectWithValue }) => {
      try {
        const { id } = args;
        const message = await api.lawyerConnection.postMessage({
          id,
          message: args.message,
        });
        return message;
      } catch (error) {
        console.error('send message', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  updateReadAt: createAsyncThunk(
    `${STORE}/updateReadAt`,
    async (args: { id: string }, { rejectWithValue }) => {
      try {
        const { id } = args;
        await api.lawyerConnection.updateReadAt({ id });
        return Date.now();
      } catch (error) {
        console.error('update read at', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  fetchClientIntakeForm: createAsyncThunk(
    `${STORE}/fetchClientIntakeForm`,
    async (args: { connectionId: string }, { rejectWithValue }) => {
      try {
        const { connectionId } = args;
        const response = await api.lawyerConnection.fetchClientIntakeForm({
          connectionId,
        });
        return response;
      } catch (error) {
        console.error('fetch client intake form', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  updateClientIntakeForm: createAsyncThunk(
    `${STORE}/updateClientIntakeForm`,
    async (
      args: { connectionId: string; form: ClientIntakeForm },
      { rejectWithValue }
    ) => {
      try {
        const { connectionId, form } = args;
        await api.lawyerConnection.updateClientIntakeForm({
          connectionId,
          form,
        });
        return form;
      } catch (error) {
        console.error('update client intake form', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  markClientIntakeFormAsCompleted: createAsyncThunk(
    `${STORE}/markAsCompleted`,
    async (args: { connectionId: string }, { rejectWithValue }) => {
      try {
        const { connectionId } = args;
        const { form, message } =
          await api.lawyerConnection.markClientIntakeFormAsCompleted({
            connectionId,
          });
        return { form, message };
      } catch (error) {
        console.error('mark as completed', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
};

export default lawyerConnectionThunk;
