import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import { FormFileInput } from '../../../components/form/elements/file-input';
import { Button } from '../../../components/button';
import UploadIcon from '../../../assets/icons/upload.svg?react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import selectors from '../../../redux/selectors';
import { actions } from '../../../redux/slices';
import { usePrevious } from '../../../hooks/use-previous';
import { LegalCaseState } from '@law-connect/types';
import { VerifyLockedMessage } from '../../../components/verify-locked-message';
import { isVerifyDisabled } from '../../../utils/is-verify-disabled';

interface Props {
  id: string;
  prematterState?: LegalCaseState;
}

export const UploadComponent: FC<Props> = (props) => {
  const { id, prematterState } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<File[]>([]);
  const isUploadPending = useAppSelector(selectors.file.isUploadPending());
  const isUploading = useMemo(
    () => Object.values(isUploadPending).some((v) => v),
    [isUploadPending]
  );
  const prevIsUploading = usePrevious(isUploading);
  const disabled = isVerifyDisabled(prematterState);

  const isUploadingFile = useCallback(
    (fileName: string): boolean => {
      return isUploadPending[fileName];
    },
    [isUploadPending]
  );

  const uploadFiles = useCallback(() => {
    if (files.length && !isUploading) {
      files.forEach((f) => {
        dispatch(actions.file.upload({ file: f, prematterId: id }));
      });
    }
  }, [dispatch, files, id, isUploading]);

  const removeFile = useCallback(
    (index: number) => {
      if (!isUploadPending[files[index].name]) {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
      }
    },
    [files, isUploadPending]
  );

  const addFiles = useCallback(
    (newFiles: File[]) => {
      const nf = newFiles.filter(
        (f) => !files.some((file) => file.name === f.name)
      );
      if (nf.length) {
        setFiles([...files, ...nf]);
      }
    },
    [files]
  );

  useEffect(() => {
    if (prevIsUploading && !isUploading) {
      setFiles([]);
    }
  }, [isUploading, prevIsUploading]);

  return (
    <div className={style.formWrapper}>
      <div className={style.form}>
        <FormFileInput
          addFiles={addFiles}
          files={files}
          isFileUploading={isUploadingFile}
          removeFile={removeFile}
          disabled={isUploading || disabled}
        />
        <Button
          label={t('prematter.upload')}
          onClick={uploadFiles}
          className={`${style.uploadButton} ${
            files.length ? style.showMobile : ''
          } ${!files.length || isUploading ? style.disabled : ''}`}
          icon={<UploadIcon className={style.uploadIcon} />}
          disabled={!files.length || isUploading || disabled}
        />
      </div>
      <VerifyLockedMessage prematterState={prematterState} />
    </div>
  );
};
