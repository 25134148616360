import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import WarningCircle from '../../assets/icons/warning-circle.svg?react';

interface Props {
  showError: boolean;
  errorMessage?: string;
  action?: {
    text: string;
    onClick: () => void;
  };
}

export const ErrorHandlerComponent: React.FC<Props> = (props) => {
  const { showError, errorMessage, action } = props;
  const { t } = useTranslation();

  return (
    showError && (
      <div className={style.errorContainer}>
        <WarningCircle className={style.icon} />
        <div>{errorMessage ?? t('chat.error-message')}</div>
        {action ? (
          <>
            &nbsp;
            <div onClick={action.onClick} className={style.actionButton}>
              {action.text}
            </div>
          </>
        ) : null}
      </div>
    )
  );
};
