import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import {
  NarrowWrapper,
  WrapperOffsetSide,
} from '../../components/narrow-wrapper';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import { Wrapper } from '../../components/wrapper';
import { Link, useNavigate } from 'react-router-dom';
import { CONTACT_US_LINK } from '../../constants';
import { Button } from '../../components/button';
import { PageTitleHero } from '../../components/page-title-hero';

export const NotFoundComponent: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goHome = useCallback(() => navigate('/'), [navigate]);
  return (
    <>
      <Header />

      <PageTitleHero title={'404'} titleClassName={style.headerText} />
      <NarrowWrapper
        offset={WrapperOffsetSide.LEFT}
        offsetClassName={style.offsetContainer}
      >
        <div className={style.body}>
          <div className={style.textWrapper}>
            <div>{t('pages.not-found.title')}</div>
            <div className={style.lineBreak}>
              {t('pages.not-found.urlText')}
            </div>
            <div>
              {t('pages.not-found.text1')}{' '}
              <a href={CONTACT_US_LINK}>
                {t('pages.contact-us').toLocaleLowerCase()}
              </a>
              {t('pages.not-found.text2')}
            </div>
          </div>
          <div className={style.homeButtonWrapper}>
            <Button
              label={t('pages.home')}
              labelClassName={style.homeButtonLabel}
              iconClassName={style.homeButtonIcon}
              onClick={goHome}
              className={style.homeButton}
            />
          </div>
        </div>
        <div className={style.notFoundInfoContainer}>
          {t('pages.not-found.info')}
        </div>
      </NarrowWrapper>
      <Footer />
    </>
  );
};
