import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import { ContentTitle } from '../content-title';
import { MessageRowComponent } from './row';
import { useAppSelector } from '../../redux/hooks';
import selectors from '../../redux/selectors';
import { LawyerConnection, Prematter } from '@law-connect/types';

export const AccountMessages: FC = () => {
  const { t } = useTranslation();

  const connections = useAppSelector(
    selectors.lawyerConnection.getConnections()
  );
  const prematters = useAppSelector(selectors.prematter.getAll());

  return connections?.length ? (
    <div className={style.container}>
      <ContentTitle
        title={t('connect.messages')}
        className={style.contentTitle}
      />
      <div className={style.rowContainer}>
        <LawyerMessages 
          connections={connections}
          prematters={prematters}
        />
      </div>
    </div>
  ) : null;
};

interface Props {
  connections: LawyerConnection[];
  prematters: Prematter[];
}

export const LawyerMessages: FC<Props> = (props) => {
  const { connections, prematters } = props;
  return connections.map((message) => {
    const prematter = prematters.find(
      (prematter) => prematter.id === message.prematterId
    );
    if(!prematter) {
      return null;
    }
    return (
      <MessageRowComponent
        key={message.id}
        id={message.id}
        prematterName={prematter.name}
        name={`${message.lawyer.firstName} ${message.lawyer.lastName}`.trim()}
        timestamp={message.createdAt}
        image={message.lawyer.image}
        isNew={message.readAt == null}
      />
    );
  });
};
