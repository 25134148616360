import React, {
  createRef,
  useCallback,
  useEffect,
  useRef,
  useState,
  MouseEventHandler,
} from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import style from './style.module.less';
import AuthModal, { LoginModalRef } from '../auth-modal';
import { LanguageSelector } from '../../components/language-selector';
import HamburgerIcon from '../../assets/icons/hamburger.svg?react';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { AUTH_MODAL_PORTAL_ID, CONTACT_US_LINK } from '../../constants';
import { LogoImageComponent } from './logo';
import { CloseIconComponent } from './close';
import { WidthType, useScreenSize } from '../../hooks/use-is-mobile';
import { usePrevious } from '../../hooks/use-previous';
import { createPortal } from 'react-dom';

export const MENU_HEADER_ID = 'MENU_HEADER_ID';
export const MENU_CONTENT_ID = 'MENU_CONTENT_ID';
// we want the height to shrink on large desktop on scroll to a min of 100px
const MENU_HEIGHT = 100;
const MAX_HEIGHT = 144;
const LARGE_DESKTOP_WIDTH = 1601;

export const Header: React.FC = () => {
  const { isAuthenticated } = useKindeAuth();
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const prevMenuOpen = usePrevious(isMenuOpen);
  const authModalRef = createRef<LoginModalRef>();
  const menuRef = useRef<HTMLDivElement>(null);
  const screenType = useScreenSize();
  const portalDiv = document.createElement('div');

  const closeMenu = useCallback(() => setIsMenuOpen(false), []);

  const checkScrollPosition = useCallback(() => {
    if (window.innerWidth > LARGE_DESKTOP_WIDTH) {
      if (window.scrollY < MAX_HEIGHT - MENU_HEIGHT) {
        menuRef.current.style.maxHeight = `${MAX_HEIGHT - window.scrollY}px`;
      } else {
        menuRef.current.style.maxHeight = `${MENU_HEIGHT}px`;
      }
    }
  }, []);

  const handleResize = useCallback(() => {
    checkScrollPosition();
    setIsMenuOpen(false);
  }, [checkScrollPosition]);

  useEffect(() => {
    // Initial check
    checkScrollPosition();

    // Event listener for scroll
    window.addEventListener('scroll', checkScrollPosition);
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
      window.removeEventListener('resize', handleResize);
    };
  }, [checkScrollPosition, handleResize]);

  // on menu open we want to scroll to the top of the page on mobile
  useEffect(() => {
    if (screenType === WidthType.Mobile) {
      if (isMenuOpen && !prevMenuOpen) {
        window.scrollTo(0, 0);
      }
    }
  }, [isMenuOpen, prevMenuOpen, screenType]);

  const openLoginModal: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (e) => {
      closeMenu();
      if (!isAuthenticated) {
        e.preventDefault();
        authModalRef.current?.openModal();
      }
    },
    [authModalRef, isAuthenticated, closeMenu]
  );

  const BODY = (
    <>
      <div ref={menuRef} id={MENU_HEADER_ID} className={style.header}>
        <div className={style.content} id={MENU_CONTENT_ID}>
          <LogoImageComponent isMenuOpen={isMenuOpen} />
          <div
            className={style.hamburger}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <HamburgerIcon />
          </div>
          <CloseIconComponent isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
          <div className={`${style.menu} ${isMenuOpen ? style.show : ''}`}>
            <NavLink
              to='/'
              className={({ isActive }) =>
                `${style.menuItem} ${isActive ? style.active : ''}`
              }
              onClick={closeMenu}
            >
              <div className={style.textWrapper} title={t('pages.home')}>
                {t('pages.home')}
              </div>
            </NavLink>
            {/* <NavLink
              to='/how-it-works'
              className={({ isActive }) =>
                `${style.menuItem} ${isActive ? style.active : ''}`
              }
              onClick={closeMenu}
            >
              {t('pages.how-it-works')}
            </NavLink> */}
            <NavLink
              to='/about'
              className={({ isActive }) =>
                `${style.menuItem} ${isActive ? style.active : ''}`
              }
              onClick={closeMenu}
            >
              <div className={style.textWrapper} title={t('pages.about')}>
                {t('pages.about')}
              </div>
            </NavLink>
            <NavLink
              to='/account'
              onClick={openLoginModal}
              className={({ isActive }) =>
                `${style.menuItem} ${isActive ? style.active : ''}`
              }
            >
              {/* we use title to force set the width to bold */}
              <div className={style.textWrapper} title={t('pages.account')}>
                {t('pages.account')}
              </div>
            </NavLink>
            <div className={style.subMenu}>
              <LanguageSelector isMenuOpen={isMenuOpen} closeMenu={closeMenu} />
              <a className={style.title} href={CONTACT_US_LINK}>
                {t('pages.contact-us')}
              </a>
            </div>
          </div>
        </div>
        <AuthModal ref={authModalRef} />
      </div>
    </>
  );

  if(document.getElementById(AUTH_MODAL_PORTAL_ID)) {
    return createPortal(BODY, document.getElementById(AUTH_MODAL_PORTAL_ID));
  } else {
    return BODY;
  }
};
