import React, { useCallback, useEffect, useRef, useState } from 'react';
import style from './style.module.less';
import LanguageIcon from '../../assets/icons/language.svg?react';
import ChevronDownIcon from '../../assets/icons/chevron-down.svg?react';
import ChevronUpIcon from '../../assets/icons/chevron-up.svg?react';
import { useTranslation } from 'react-i18next';
import { DropdownComponent } from './dropdown';
import useStopScroll from '../../hooks/use-stop-scroll';

interface Props {
  isMenuOpen: boolean;
  closeMenu: () => void;
}

export const LanguageSelector: React.FC<Props> = (props) => {
  const { isMenuOpen, closeMenu } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  // we want to close the dropdown if the mouse leaves the dropdown or the menu content
  useStopScroll(isOpen || isMenuOpen);

  // ref used to check if mouse is moved out of the div to close the dropdown
  const languageRef = useRef<HTMLDivElement>(null);
  // need the portal to inject into the content to get the full width
  // we could pase the ref down into the menu

  const toggleOpen = useCallback(() => setIsOpen((o) => !o), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  const closeMenuWrapper = useCallback(() => {
    setIsOpen(false);
    closeMenu();
  }, [closeMenu]);

  return (
    <div
      ref={languageRef}
      className={`${style.container} ${isOpen ? style.open : ''}`}
    >
      <div className={style.languageIconContainer} onClick={toggleOpen}>
        <div className={style.languageText}>{t('language.language')}</div>
        <LanguageIcon className={style.languageIcon} />
        {isOpen ? (
          <ChevronUpIcon className={style.chevronIcon} />
        ) : (
          <ChevronDownIcon className={style.chevronIcon} />
        )}
      </div>
      <DropdownComponent
        isOpen={isOpen}
        closeModal={closeModal}
        closeMenu={closeMenuWrapper}
      />
      {isOpen && <div className={style.bottomBar} />}
    </div>
  );
};
