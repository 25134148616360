import { ChatElement, ChatElementRef } from '@law-connect/react-components';
import { Message, MessageType } from '@law-connect/types';
import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { COOKIE_ID } from '../../components/cookie-consent';
import style from './style.module.less';
import { useHandleChatResize } from '../chat/chatbox/use-handle-chat-resize';
import { LawyerIntro } from './lawyer-intro';

interface Props {
  messages: Message[];
  isLoading: boolean;
  onSendMessage: (args: { text?: string; files?: File[] }) => void;
}

export const ChatWrapper: FC<Props> = (props) => {
  const { messages, isLoading, onSendMessage } = props;
  const chatRef = useRef<ChatElementRef>(null);
  const placeholderRef = useRef<HTMLDivElement>(null);
  const [textareaHeight, setTextAreaHeight] = useState(null);
  const [isFloating, setIsFloating] = useState(true);
  const timer = useRef(null);

  // if in chat on mobile we want to set the cookie header to not fixed
  useEffect(() => {
    const cookieConsent = document.getElementById(COOKIE_ID);
    if (cookieConsent) {
      cookieConsent.className = `${cookieConsent.className} inChat`;
    }

    return () => {
      if (cookieConsent) {
        cookieConsent.className = cookieConsent.className.replace('inChat', '');
      }
    };
  }, []);

  // we can use the observer to fetch the height of the textarea (this is a life saver)
  useEffect(() => {
    if (chatRef?.current?.inputRef?.current) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setTextAreaHeight(entry.borderBoxSize[0].blockSize);
        }
      });
      observer.observe(chatRef.current.inputRef.current);
      // Clean up the observer on component unmount
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  // handle resizing of chat input
  const calcHeightResize = useCallback(
    (noCookie?: boolean) => {
      if (chatRef.current?.chatRef && chatRef.current?.inputRef) {
        const textarea = chatRef.current.inputRef.current;
        const chatBodyRef = chatRef.current.chatRef.current;
        if (textarea) {
          if (chatBodyRef) {
            // we also want to check if the cookie consent is present and if it is we want to make the chat window body smaller
            const cookieConsent = document.getElementById(COOKIE_ID);
            let ch = 0;
            if (cookieConsent?.getBoundingClientRect()?.height && !noCookie) {
              ch = cookieConsent.getBoundingClientRect().height;
            }
            textarea.style.bottom = `${ch}px`;
            placeholderRef.current.style.bottom = `${ch}px`;
            chatBodyRef.style.minHeight = `calc(100vh - ${
              chatBodyRef.offsetTop
            }px - ${ch}px)`;
            chatBodyRef.style.paddingBottom =
              `${textarea.getBoundingClientRect().height}px`;
          }
        }
      }
    },
    [chatRef]
  );

  useHandleChatResize({
    calcHeightResize,
    files: [],
    message: '',
    height: textareaHeight,
  });

  // we want to check if chat and input are always touching to set the style of the input
  useEffect(() => {
    const handleCrossing = () => {
      const textarea = chatRef?.current?.inputRef?.current;
      const chatBodyRef = chatRef?.current?.chatRef?.current;
      if (textarea && chatBodyRef && placeholderRef.current) {
        if (
          placeholderRef.current.getBoundingClientRect().bottom <=
          chatBodyRef.getBoundingClientRect().bottom +
            textarea.getBoundingClientRect().height
        ) {
          setIsFloating(true);
        } else {
          setIsFloating(false);
        }
      }
    };

    const timeoutWrapper = () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        handleCrossing();
      }, 10);
    };

    window.addEventListener('scroll', timeoutWrapper);

    return () => {
      window.removeEventListener('scroll', timeoutWrapper);
    };
  }, []);

  const goToBottom = useCallback(() => {
    // scroll position is not pass the chat window then scroll to the bottom
    if (isFloating) {
      chatRef.current.chatRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [isFloating]);

  useLayoutEffect(() => {
    goToBottom();
  }, [goToBottom, messages]);

  return (
    <>
      <ChatElement
        ref={chatRef}
        messages={messages.map((m) =>
          m.type === MessageType.ClientIntakeForm
            ? {
              ...m,
              type: MessageType.Chat,
              content:
                  'Client intake form request.\nDo something amazing Daniel',
            }
            : m
        )}
        isLoading={isLoading}
        onSendMessage={onSendMessage}
        className={style.chatWrapper}
        inputClassName={`${style.chatInput} ${
          isFloating ? style.isFloating : style.notFloating
        }`}
        style={
          { '--chat-height': `${textareaHeight}px` } as React.CSSProperties
        }
        withHover={true}
      >
        <LawyerIntro />
      </ChatElement>
      <div
        className={`${style.heightPlaceholder} ${
          isFloating ? style.isFloating : style.notFloating
        }`}
        style={{ height: `${textareaHeight}px` }}
      />
      <div ref={placeholderRef} className={style.placeholder} />
    </>
  );
};
