import React from 'react';
import style from './style.module.less';
import { useNavigate } from 'react-router-dom';
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg?react';
import { ContextItem, ContextMenu } from '../context-menu';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';

interface Props {
  items?: ContextItem[];
  className?: string;
  children?: React.ReactNode;
}

export const BackHeader: React.FC<Props> = (props) => {
  const { className, items, children } = props;
  const { isAuthenticated } = useKindeAuth();

  const navigate = useNavigate();

  const handleGoBack = () => {
    // 💡 Verify if previous page exists before using router.back
    const hasPreviousPage = window.history.length > 1;

    if (hasPreviousPage) {
      if (window.history.state?.prevPath === '/' || !isAuthenticated) {
        navigate('/');
      } else {
        navigate('/account');
      }
    } else {
      // fallback to a meaningful route.
      navigate('/');
    }
  };

  return (
    <div className={`${style.prematterHeader} ${className ?? ''}`}>
      <div className={style.wrapper}>
        <div className={style.backHandler}>
          <a href={void 0} onClick={handleGoBack} className={style.icon}>
            <ArrowLeftIcon />
          </a>
        </div>
        {children}
        {items && (
          <div className={style.options}>
            <ContextMenu items={items} />
          </div>
        )}
      </div>
    </div>
  );
};
