import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import style from './style.module.less';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { actions } from '../../../../redux/slices';
import selectors from '../../../../redux/selectors';
import PlusIcon from '../../../../assets/icons/plus.svg?react';
import { usePrevious } from '../../../../hooks/use-previous';
import { FAQAddQuestion } from '../../../../components/faq-add-question';
import { 
  VerifyLockedMessage
} from '../../../../components/verify-locked-message';
import { LegalCaseState } from '@law-connect/types';

interface Props {
  id: string;
  prematterState: LegalCaseState;
}

export const AddQuestionComponent: FC<Props> = (props) => {
  const { id, prematterState } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const [openQuestion, setOpenQuestion] = useState(false);
  const [hasTyped, setHasTyped] = useState(true);
  const [question, setQuestion] = useState('');
  const addQuestionError = useAppSelector(
    selectors.prematter.getAddQuestionError(id)
  );
  const isAddQuestionPending = useAppSelector(
    selectors.prematter.isAddQuestionPending(id)
  );
  const prevIsAddQuestionPending = usePrevious(isAddQuestionPending);

  const onClickButton = useCallback(() => setOpenQuestion(true), []);
  const closeQuestion = useCallback(() => setOpenQuestion(false), []);

  const onInputChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setHasTyped(true);
    setQuestion(e.target.value);
  }, []);

  const submitQuestion = useCallback(() => {
    if (!isAddQuestionPending) {
      dispatch(actions.prematter.addQuestion({ id, question }));
      setHasTyped(false);
    }
  }, [dispatch, id, isAddQuestionPending, question]);

  useEffect(() => {
    if (
      !isAddQuestionPending &&
      prevIsAddQuestionPending &&
      !addQuestionError
    ) {
      setQuestion('');
    }
  }, [addQuestionError, isAddQuestionPending, prevIsAddQuestionPending]);

  return (
    <div className={style.container}>
      <div
        id='add-question'
        style={{
          position: 'absolute',
          top: '-35vh',
          visibility: 'hidden',
        }}
      />
      {openQuestion ? (
        <>
          <FAQAddQuestion
            disabled={hasTyped}
            pending={isAddQuestionPending}
            question={question}
            show={openQuestion}
            onChange={onInputChange}
            submit={submitQuestion}
            error={addQuestionError}
            buttonClassName={style.submitButton}
            inputClassName={style.inputContainer}
            className={style.faqContainer}
          />
          <div onClick={closeQuestion} className={style.cancelText}>
            {t('confirm.cancel')}
          </div>
        </>
      ) : (
        <button
          className={style.addQuestionButton}
          onClick={onClickButton}
          // we disable only if verified
          disabled={prematterState === LegalCaseState.Verified}
        >
          {t('prematter.add-question')}
          <PlusIcon className={style.arrowRight} />
        </button>
      )}
      <VerifyLockedMessage prematterState={prematterState} skipPending={true} />
    </div>
  );
};
