import React from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import { Wrapper } from '../../components/wrapper';

export const MaintenanceComponent: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Header />
      <Wrapper className={style.wrapper}>
        <div className={style.headerContainer}>
          <div className={style.textContainer}>
            <div className={style.headerText}>{t('pages.maintenance')}</div>
            <div className={style.bodyText}>
              <div
                dangerouslySetInnerHTML={{
                  __html: t('pages.maintenance-body'),
                }}
              />
            </div>
          </div>
        </div>
      </Wrapper>
      <Footer />
    </>
  );
};
