import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { actions } from '../../../redux/slices';
import {
  Form,
  FormSource,
  IntroductoryForm,
  LegalCaseState,
  MessageType,
  Prematter,
  Question,
  UploadedFile,
} from '@law-connect/types';
// import { InlineForm } from '../../../components/form/inline-form';
import selectors from '../../../redux/selectors';
import { ErrorHandlerComponent } from '../../../components/error-handler';
import { Settify, SettifyRef } from '../../../components/settify';
import { EmptyNextStepCard } from '../../../components/next-step-card';
import { getVariantFromPrematter } from '../../../utils/get-variant';
import { formToSettifyValues } from '../../../utils/form-to-settify';
import { canUseSettify } from '../../../utils/can-use-settify';
import ChatMessage from '../../../components/chat-history/message';
import { InlineForm } from '@law-connect/react-components';
import { isPrematterComplete } from '../../../utils/is-prematter-complete';

interface Props {
  prematter: Prematter;
  readOnly?: boolean;
}

const SETTIFY_SECTION_COUNT = 12;
const FormComponent: FC<Props> = (props) => {
  const { prematter, readOnly } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation(undefined, { lng: prematter.language });
  const submitFormError = useAppSelector(selectors.session.sendFormError());
  const isSendingForm = useAppSelector(selectors.session.isSendFormPending());
  const files = useAppSelector(selectors.file.getPrematterFiles(prematter.id));

  const settifyRef = useRef<SettifyRef>(null);
  const [progress, setProgress] = useState<number>(
    1 / (SETTIFY_SECTION_COUNT + 1)
  );

  const useSettify = canUseSettify(prematter);

  const useInlineExtendedForm = getVariantFromPrematter(prematter) === 'A';

  const useSettifyAndInlineForm = useSettify && useInlineExtendedForm;

  useEffect(() => {
    if (prematter.state === LegalCaseState.ExtendedForm && progress < 100) {
      dispatch(
        actions.session.updateExtendedForm({
          id: prematter.id,
          extendedForm: {
            complete: false,
            data: settifyRef.current?.getData(),
          },
        })
      );
    }
  }, [prematter.state, prematter.id, progress, dispatch]);

  // const onComplete = () => {
  //   dispatch(
  //     actions.session.updateExtendedForm({
  //       id: prematter.id,
  //       extendedForm: {
  //         complete: true,
  //         data: settifyRef.current?.getData(),
  //       },
  //       generate: true,
  //     })
  //   );
  // };

  // const onFinish = (form: Form) => {
  //   if (useSettify) {
  //     if (useInlineExtendedForm) {
  //       // By sending generate: false, this will put the prematter into a extended-form state
  //       dispatch(
  //         actions.session.sendForm({
  //           form,
  //           generate: false,
  //         })
  //       );
  //     } else {
  //       dispatch(
  //         actions.session.sendForm({
  //           form,
  //           generate: false,
  //         })
  //       );
  //     }
  //   } else {
  //     dispatch(
  //       actions.session.sendForm({
  //         form,
  //       })
  //     );
  //   }
  // };

  // const onProgressUpdate = (p: number, items: number) => {
  //   const progress = (p / 100) * items + 1;

  //   if (p === 0) {
  //     setProgress((1 / (items + 1)) * 100);
  //   } else if (p !== progress) {
  //     setProgress((((p / 100) * items + 1) / (items + 1)) * 100);
  //   }
  // };

  const onProgress = (q: Question[]) => {
    console.log('progress', q);
  };

  const onCompletion = (questions: Question[]) => {
    console.log('we are compelete');
    dispatch(
      actions.session.sendForm({
        form: {
          ...prematter.context.form,
          questions,
        },
      })
    );
  };

  if (!prematter) {
    return null;
  }

  return (
    <>
      <ChatMessage
        chatState={prematter?.state}
        chat={{
          id: 'confirm.confirm',
          content: t('confirm.confirm'),
          from: 'client',
          type: MessageType.Confirmation,
          createdAt: prematter?.messages?.length
            ? prematter.messages[prematter.messages.length - 1]?.createdAt
            : null,
        }}
        prematter={prematter}
      />
      <ChatMessage
        chatState={prematter?.state}
        chat={{
          id: 'preform-message',
          content: t('chat.preform-message'),
          from: 'lawyer',
          type: MessageType.Chat,
          createdAt: prematter?.messages?.length
            ? prematter.messages[prematter.messages.length - 1]?.createdAt
            : null,
        }}
        prematter={prematter}
      />

      <div
        className={`${style.formWrapper} ${
          useSettifyAndInlineForm ? style.inlineFormWrapper : ''
        }`}
      >
        {prematter.context.form ? <InlineForm
          title={t('chat.questionnaire')}
          form={prematter.context.form}
          onChange={onProgress}
          onComplete={onCompletion}
          complete={isPrematterComplete(prematter.state) ||
            prematter.state === LegalCaseState.ExtendedForm ||
            prematter.state === LegalCaseState.AuthCheck}
          files={files}
          onFileUpload={(files) => {
            files.forEach((file) => {
              dispatch(
                actions.file.upload({
                  prematterId: prematter.id,
                  file,
                })
              );
            });
          }}
          onFileRemove={(file) => {
            dispatch(
              actions.file.delete({
                id: file.id,
                prematterId: prematter.id,
              })
            );
          }}
        />: 'No form found.'}
        {/* {useSettifyAndInlineForm &&
        prematter.state === LegalCaseState.ExtendedForm ? (
            <div className={`${style.extendedForm} ${style.active}`}>
              <EmptyNextStepCard
                title='Questionnaire'
                progress={progress}
                style={{ borderRadius: '16px' }}
              >
                <Settify
                  ref={settifyRef}
                  rootUrl={window.location.pathname}
                  continuous
                  setProgress={onProgressUpdate}
                  onComplete={onComplete}
                  data={prematter?.context.extendedForm?.data}
                  prefill={formToSettifyValues(
                    prematter?.context.form?.questions
                  )}
                />
              </EmptyNextStepCard>
            </div>
          ) : (
            <InlineForm
              prematter={prematter}
              onFinish={onFinish}
              readOnly={readOnly}
              submitting={isSendingForm}
              onFinishLabel={useSettifyAndInlineForm ? '' : undefined}
              extendedFormLength={
                useSettifyAndInlineForm ? SETTIFY_SECTION_COUNT : 0
              } // handles progress bar for settify
            />
          )} */}
      </div>
      <ErrorHandlerComponent
        showError={submitFormError != null}
        errorMessage={t('chat.submit-form-error')}
      />
    </>
  );
};

export default FormComponent;
