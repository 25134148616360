import { createSlice } from '@reduxjs/toolkit';
import { actions } from '.';
import { File } from '@law-connect/types';

interface FileState {
  files: File[];
  // list of files that have been uploaded but not yet associated with a prematter
  uploadedTemp: File[];
  pending: {
    fetchByPrematterId?: { [prematterId: string]: boolean };
    delete: boolean;
    upload: { [filename: string]: boolean };
  };
  errors: {
    fetchByPrematterId?: { [prematterId: string]: string | null };
    upload: string | null;
    delete: string | null;
  };
}

const initialState: FileState = {
  files: [],
  uploadedTemp: [],
  pending: {
    fetchByPrematterId: {},
    upload: {},
    delete: false,
  },
  errors: {
    fetchByPrematterId: {},
    delete: null,
    upload: null,
  },
};

const fileSlice = createSlice({
  name: 'file',
  initialState,
  // you cant have a reducer name match the thunk action
  reducers: {
    clearTemp: (state) => {
      state.uploadedTemp = [];
    },
    clearPending: (state) => {
      state.pending = initialState.pending;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      actions.file.fetchByPrematterId.pending,
      (state, action) => {
        state.pending.fetchByPrematterId[action.meta.arg.prematterId] = true;
        state.errors.fetchByPrematterId[action.meta.arg.prematterId] = null;
      }
    );
    builder.addCase(
      actions.file.fetchByPrematterId.fulfilled,
      (state, action) => {
        state.pending.fetchByPrematterId[action.meta.arg.prematterId] = false;
        state.errors.fetchByPrematterId[action.meta.arg.prematterId] = null;
        // we want to replace any existing files with the new ones
        // state.files = [...state.files, ...action.payload];
        const existingFiles = new Map(state.files.map((f) => [f.id, f]));
        action.payload.forEach(newObj => {
          // If the object already exists, update it
          if (existingFiles.has(newObj.id)) {
            existingFiles.set(newObj.id, newObj);
          } else {
            // If it doesn't exist, add it
            existingFiles.set(newObj.id, newObj);
          }
        });
        state.files = Array.from(existingFiles.values());
      }
    );
    builder.addCase(
      actions.file.fetchByPrematterId.rejected,
      (state, action) => {
        state.pending.fetchByPrematterId[action.meta.arg.prematterId] = false;
        state.errors.fetchByPrematterId[action.meta.arg.prematterId] =
          (action.payload as string) ?? 'unknown error';
      }
    );
    builder.addCase(actions.file.upload.pending, (state, action) => {
      state.pending.upload[action.meta.arg.file.name] = true;
      state.errors.upload = null;
    });
    builder.addCase(actions.file.upload.fulfilled, (state, action) => {
      state.pending.upload[action.payload.name] = false;
      state.uploadedTemp.push(action.payload);
      state.files = [...state.files, action.payload];
    });
    builder.addCase(actions.file.upload.rejected, (state, action) => {
      state.pending.upload[action.meta.arg.file.name] = false;
      state.errors.upload = (action.payload as string) ?? 'unknown error';
    });

    builder.addCase(actions.file.delete.pending, (state, action) => {
      state.pending.delete = true;
      state.errors.delete = null;
    });
    builder.addCase(actions.file.delete.fulfilled, (state, action) => {
      state.pending.delete = false;
      state.files = state.files.filter((f) => f.id !== action.meta.arg.id);
    });
    builder.addCase(actions.file.delete.rejected, (state, action) => {
      state.pending.delete = false;
      state.errors.delete = (action.payload as string) ?? 'unknown error';
    });
  },
});

// im not sure if this is good practice but it mimics what we used to do with dispatching actions
export const fileActions = fileSlice.actions;
export const fileReducer = fileSlice.reducer;
