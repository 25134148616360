import React, { FC } from 'react';
import style from './style.module.less';
import { Header } from '../../../components/header';
import { PrematterHeader } from '../../../components/prematter-header';
import { Prematter } from '@law-connect/types';
import { PercentageSpinner } from '../../../components/percentage-spinner';
import { useTranslation } from 'react-i18next';
import { Footer } from '../../../components/footer';

interface Props {
  prematter: Prematter;
}

export const PreparingReportComponent: FC<Props> = (props) => {
  const { prematter } = props;
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <PrematterHeader prematter={prematter} tabs={false} noExtension={true} />
      <div className={style.wrapper}>
        <div className={style.loading}>
          <PercentageSpinner
            percentage={Math.round(prematter?.reportCompletionPercentage) ?? 0}
          />
          <div
            className={style.loadingText}
            dangerouslySetInnerHTML={{
              __html: t('prematter.please-wait-generating'),
            }}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};
