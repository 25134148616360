import i18n from 'i18next';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ExtendedForm, IntroductoryForm } from '@law-connect/types';
import api from '../../api';

const STORE = 'prematter';
const prematterThunk = {
  fetch: createAsyncThunk(`${STORE}/fetch`, async (_, { rejectWithValue }) => {
    try {
      const response = await api.prematter.fetch();
      return response;
    } catch (error) {
      console.error('fetch prematters', error);
      toast.error(i18n.t('toast.general-error'));
      return rejectWithValue(error.message as string);
    }
  }),
  fetchById: createAsyncThunk(
    `${STORE}/fetchById`,
    async (args: { id: string }, { rejectWithValue }) => {
      try {
        const response = await api.prematter.fetchById({ id: args.id });
        return response;
      } catch (error) {
        console.error('fetch prematter by id', error);
        toast.error(i18n.t('toast.general-error'));
        return rejectWithValue(error.message as string);
      }
    }
  ),
  update: createAsyncThunk(
    `${STORE}/update`,
    async (args: { name: string; id: string }, { rejectWithValue }) => {
      try {
        const response = await api.prematter.updateName(args);
        toast.success(i18n.t('toast.update-case-success'));
        return response;
      } catch (error) {
        toast.error(i18n.t('toast.update-error'));
        return rejectWithValue(error.message as string);
      }
    }
  ),
  delete: createAsyncThunk(
    `${STORE}/delete`,
    async (args: { id: string }, { rejectWithValue }) => {
      try {
        await api.prematter.delete(args);
        toast.success(i18n.t('toast.delete-case-success'));
        return args;
      } catch (error) {
        console.error('failed to delete prematter', error);
        toast.error(i18n.t('toast.delete-error'));
        return rejectWithValue(error.message as string);
      }
    }
  ),
  regenerate: createAsyncThunk(
    `${STORE}/regenerate`,
    async (
      args: { id: string; form: IntroductoryForm },
      { rejectWithValue }
    ) => {
      try {
        const response = await api.prematter.regenerate(args);
        return response;
      } catch (error) {
        console.error('failed to regenerate prematter', error);
        toast.error(i18n.t('toast.regenerate-case-error'));
        return rejectWithValue(error.message as string);
      }
    }
  ),
  addQuestion: createAsyncThunk(
    `${STORE}/addQuestion`,
    async (args: { id: string; question: string }, { rejectWithValue }) => {
      try {
        const response = await api.prematter.addQuestion(args);
        return response;
      } catch (error) {
        console.error('failed to add question', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  deleteQuestion: createAsyncThunk(
    `${STORE}/deleteQuestion`,
    async (args: { id: string; questionId: string }, { rejectWithValue }) => {
      try {
        await api.prematter.deleteQuestion(args);
      } catch (error) {
        console.error('failed to delete question', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  updateExtendedForm: createAsyncThunk(
    `${STORE}/updateExtendedForm`,
    async (
      args: { id: string; extendedForm: ExtendedForm; generate?: boolean },
      { rejectWithValue }
    ) => {
      try {
        const response = await api.prematter.updateExtendedForm(args);
        return response;
      } catch (error) {
        console.error('failed to update extended form', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  canVerify: createAsyncThunk(
    `${STORE}/canVerify`,
    async (args: { id: string }, { rejectWithValue }) => {
      try {
        const response = await api.prematter.canVerify(args);
        return response;
      } catch (error) {
        console.error('failed to check if prematter can be verified', error);
        return rejectWithValue(error.message as string);
      }
    }
  ),
  sendVerify: createAsyncThunk(
    `${STORE}/sendVerify`,
    async (args: { id: string }, { rejectWithValue }) => {
      try {
        const response = await api.prematter.sendVerifyAnswers(args);
        return response;
      } catch (error) {
        console.error('failed to check if prematter can be verified', error);
        toast.error(i18n.t('toast.general-error'));
        return rejectWithValue(error.message as string);
      }
    }
  ),
};

export default prematterThunk;
