import React, { useRef } from 'react';
import Markdown from 'react-markdown';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header } from '../../../components/header';
import { useAppSelector } from '../../../redux/hooks';
import selectors from '../../../redux/selectors';
import { PrematterHeader } from '../../../components/prematter-header';
import {
  ContentTitle,
  NoPaddingContentTitle,
} from '../../../components/content-title';
import {
  NarrowWrapper,
  WrapperOffsetSide,
} from '../../../components/narrow-wrapper';
import style from './style.module.less';
import { Footer } from '../../../components/footer';
import { QuestionCards } from '../../../components/question-card';
import { ReportSummary } from '../../../components/report-summary';
import CloseIcon from '../../../assets/icons/cross-circle.svg?react';
import { useCookies } from 'react-cookie';
import { PrintReportIcon } from './print';
import { AddQuestionComponent } from './add-question';
import BotImage from '../../../assets/home-assistant.png';
import { VerificationBanner } from '../verification-banner';
import { MAX_ALLOWED_QUESTION } from '../../../constants';
import { NextStepsList } from './next-steps';
import { isVerifyDisabled } from '../../../utils/is-verify-disabled';

interface Props {}
export const PrematterReport: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();

  const ref = useRef<HTMLDivElement>(null);
  // we need this mini hack because the state doesnt rerender on ref set so we need a variable change to rerender
  const [cookies, setCookie, removeCookie] = useCookies([
    'more-info-tooltip-hidden',
  ]);
  const [moreInfoTooltipHidden, setMoreInfoTooltipHidden] = React.useState(
    cookies['more-info-tooltip-hidden'] !== undefined
  );
  const prematter = useAppSelector(selectors.prematter.getById(id));
  const canBeVerified = useAppSelector(selectors.prematter.canBeVerified(id));

  const { t } = useTranslation();

  if (!prematter) {
    return null;
  }

  // TODO: bug not sure why but when changing screens the ref is not set on mount
  return (
    <>
      <div
        ref={(r) => {
          ref.current = r;
        }}
        className={style.reportTopWrapper}
      >
        <Header />
        <PrematterHeader prematter={prematter} />
        <NarrowWrapper
          offset={WrapperOffsetSide.LEFT}
          backgroundClassName={style.overviewWrapper}
          offsetClassName={style.tabletWidth}
        >
          <VerificationBanner prematter={prematter} />
          <NoPaddingContentTitle
            title={t('prematter.overview')}
            className={style.title}
          >
            <PrintReportIcon prematterId={prematter.id} />
          </NoPaddingContentTitle>
          {/* <LightButton
            label={t('share')}
            icon={<ShareIcon />}
            onClick={() => {}}
          /> */}
          <Markdown className={style.description}>
            {prematter.description}
          </Markdown>
        </NarrowWrapper>

        <NarrowWrapper
          backgroundClassName={style.darkContent}
          offset={WrapperOffsetSide.FULL}
          offsetClassName={style.tabletWidth}
        >
          <div className={style.section}>
            <ContentTitle title={t('prematter.answers')}></ContentTitle>

            {moreInfoTooltipHidden ||
            isVerifyDisabled(prematter.state) ? null : (
                <div className={style.needMoreInfo}>
                  <div className={style.needMoreInfoTitle}>
                    {t('prematter.more-info-tooltip.title')}
                  </div>
                  <div
                    className={style.close}
                    onClick={() => {
                      setCookie('more-info-tooltip-hidden', 'true', {
                        path: '/',
                        maxAge: 60 * 60 * 24 * 365,
                      });
                      setMoreInfoTooltipHidden(true);
                    }}
                  >
                    {t('confirm.close')}
                    <CloseIcon className={style.closeIcon} />
                  </div>
                  <div className={style.youCanEdit}>
                    <span className={style.desktopTitle}>
                      {t('prematter.more-info-tooltip.title')}&nbsp;
                    </span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('prematter.more-info-tooltip.explanation', {
                          interpolation: { escapeValue: false },
                        }),
                      }}
                      className={style.youCanEditText}
                    />
                  </div>
                </div>
              )}
            <QuestionCards
              questions={prematter.context.questions || []}
              prematterState={prematter.state}
            />
            {prematter.context?.questions?.length < MAX_ALLOWED_QUESTION ? (
              <AddQuestionComponent
                id={prematter?.id}
                prematterState={prematter?.state}
              />
            ) : (
              <div className={`${style.maxQuestion}`}>
                {t('prematter.max-questions')}
              </div>
            )}
          </div>
        </NarrowWrapper>
        <div className={style.nextStepWrapper}>
          <NarrowWrapper
            offset={WrapperOffsetSide.RIGHT}
            large
            contentClassName={style.nextContentWrapper}
          >
            <div className={style.botImageContainer}>
              <div className={style.imageBorderWrapper}>
                <img src={BotImage} alt='bot' className={style.botImage} />
              </div>
            </div>
            <ReportSummary>{prematter.nextSteps}</ReportSummary>
          </NarrowWrapper>
        </div>
        <div className={style.disclaimer}>
          <div
            dangerouslySetInnerHTML={{
              __html: t('auth.disclaimer'),
            }}
          />
        </div>
        {canBeVerified || isVerifyDisabled(prematter.state) ? (
          <NextStepsList prematter={prematter} />
        ) : null}
        <Footer />
      </div>
    </>
  );
};
