const loadEnv = {
  WEBSOCKET_ENDPOINT: import.meta.env.WEBAPP_WEBSOCKET_ENDPOINT,
  SERVER_ENPOINT: import.meta.env.WEBAPP_SERVER_ENDPOINT,
  KINDE_CLIENT_ID: import.meta.env.WEBAPP_KINDE_CLIENT_ID,
  KINDE_DOMAIN: import.meta.env.WEBAPP_KINDE_DOMAIN,
  KINDE_CLIENT_SECRET: import.meta.env.WEBAPP_KINDE_CLIENT_SECRET,
  KINDE_REDIRECT_URI: import.meta.env.WEBAPP_KINDE_REDIRECT_URI,
  KINDE_LOGOUT_ENDPOINT: import.meta.env.WEBAPP_KINDE_LOGOUT_REDIRECT_URI,
  KINDE_AUDIENCE: import.meta.env.WEBAPP_KINDE_AUDIENCE,
  PUBLIC_URL: import.meta.env.WEBAPP_PUBLIC_URL,
  GA_MEASUREMENT_ID: import.meta.env.WEBAPP_GA_MEASUREMENT_ID,
  RECAPTCHA_SITE_KEY: import.meta.env.WEBAPP_RECAPTCHA_SITE_KEY,
  CLARITY_PROJECT_ID: import.meta.env.WEBAPP_CLARITY_PROJECT_ID,
  EMAIL_DOMAIN: import.meta.env.WEBAPP_EMAIL_DOMAIN,
};

export const env = loadEnv;

export default loadEnv;
