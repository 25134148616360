import { Message, MessageType, Prematter } from '@law-connect/types';
import style from './style.module.less';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import ChatMessage from './message';
import { useTranslation } from 'react-i18next';
import ChatLoadingComponent from '../chat-loading';
import { v4 } from 'uuid';
import ErrorHandler from '../../containers/chat/error-handler';
import { ErrorHandlerComponent } from '../error-handler';
import { ChatStateBody } from './chat-body';
import { GOOGLE_PRIVACY_POLICY, GOOGLE_TERMS } from '../../constants';
import { FileUploading } from './uploading-files';
import { SHOW_CHAT_BOX } from '../../containers/chat/chatbox/constants';

interface Props {
  messageError?: string | null;
  readOnly?: boolean;
  prematter: Prematter;
  openLocation?: () => void;
  checkWsConnection?: () => Promise<void>;
  children?: React.ReactNode;
  addQuestion?: (args: { question: string }) => void;
  deleteQuestion?: (args: { questionId: string }) => void;
  onConfirm?: () => void;
  sendConfirmation?: (args: { result: boolean }) => void;
  addQuestionError?: string | null;
  tempMessage?: string;
  filesLoading?: boolean;
  addQuestionPending?: boolean;
  submitFormPending?: boolean;
}

export const ChatHistory: React.FC<Props> = (props) => {
  const {
    messageError,
    readOnly = false,
    prematter,
    openLocation,
    checkWsConnection,
    addQuestion,
    deleteQuestion,
    onConfirm,
    sendConfirmation,
    addQuestionError,
    tempMessage,
    filesLoading,
    addQuestionPending,
    submitFormPending,
  } = props;
  const { t } = useTranslation(undefined, { lng: prematter.language });
  const chatWrapperRef = useRef<HTMLDivElement>(null);
  const isInChat = useMemo(
    () => SHOW_CHAT_BOX.includes(prematter?.state),
    [prematter?.state]
  );
  const [showMobileTimestamps, setShowMobileTimestamps] = useState<{
    [id: string]: boolean;
  }>({});

  // callback: scrollToEnd, callbackDelay: 80
  const setShowMobileTimestamp = useCallback((id: string, state: boolean) => {
    setShowMobileTimestamps((prev) => ({ ...prev, [id]: state }));
  }, []);

  const allowedMessages = useMemo(() => {
    if ((!prematter || !prematter.id) && prematter.messages.length === 0) {
      return [
        {
          id: v4(),
          type: MessageType.Chat,
          from: 'lawyer',
          content: t('chat.helloMessage'),
          timestamp: Date.now(),
        } as Message,
        ...(prematter?.messages || []).filter(
          (m) =>
            m.type === MessageType.Chat ||
            m.type === MessageType.Location ||
            m.type === MessageType.Confirmation
        ),
      ];
    }
    const res = (prematter?.messages || []).filter(
      (m) =>
        m.type === MessageType.Chat ||
        m.type === MessageType.Location ||
        m.type === MessageType.Confirmation
    );
    if (tempMessage) {
      res.push({
        id: v4(),
        type: MessageType.Chat,
        from: 'client',
        content: tempMessage,
      });
    }

    return res;
  }, [prematter, t, tempMessage]);

  const privacyText: string = useMemo(() => {
    return t('auth.google-privacy-terms')
      .replace(/GOOGLE_TERMS/g, GOOGLE_TERMS)
      .replace(/GOOGLE_PRIVACY_POLICY/g, GOOGLE_PRIVACY_POLICY);
  }, [t]);

  if (!prematter) {
    return (
      <div className={style.errorWrapper}>
        <ErrorHandler />
      </div>
    );
  }

  return (
    <>
      <div className={style.historyWrapper}>
        {allowedMessages.length <= 1 ? (
          <div
            className={style.recaptchaTextMobile}
            dangerouslySetInnerHTML={{ __html: privacyText }}
          />
        ) : null}
        <div className={style.innerWrapper}>
          <div className={style.maxWidthWrapper} ref={chatWrapperRef}>
            {allowedMessages.map((chat, i) => (
              <ChatMessage
                key={i}
                chatState={prematter?.state}
                chat={chat}
                openLocation={openLocation ? openLocation : () => {}}
                checkWsConnection={
                  checkWsConnection ? checkWsConnection : async () => {}
                }
                lastMessage={
                  chat === allowedMessages[allowedMessages.length - 1]
                }
                sendConfirmation={sendConfirmation}
                showMobileTimestamp={showMobileTimestamps[chat.id]}
                setShowMobileTimestamp={(state: boolean) =>
                  setShowMobileTimestamp(chat.id, state)
                }
                prematter={prematter}
                addQuestionPending={addQuestionPending}
                submitFormPending={submitFormPending}
                addQuestion={addQuestion}
                addQuestionError={addQuestionError}
                deleteQuestion={deleteQuestion}
                onConfirm={onConfirm}
              />
            ))}
            <FileUploading filesLoading={isInChat && filesLoading} />
            {!readOnly && <ErrorHandler />}
            {messageError && (
              <ErrorHandlerComponent
                showError={messageError != null}
                errorMessage={messageError}
              />
            )}
            <ChatStateBody
              {...{
                prematter,
                pending: submitFormPending,
                addQuestion,
                addQuestionError,
                deleteQuestion,
                onConfirm,
                readOnly,
              }}
            />
            <ChatLoadingComponent prematter={prematter} />
          </div>
        </div>
      </div>
    </>
  );
};
