import React from 'react';
import style from './style.module.less';

export const enum WrapperOffsetSide {
  LEFT = 'left',
  RIGHT = 'right',
  FULL = 'full',
}
interface Props {
  children: React.ReactNode;
  offset?: WrapperOffsetSide;
  large?: boolean;
  backgroundClassName?: string;
  offsetClassName?: string;
  contentClassName?: string;
  className?: string;
}

export const NarrowWrapper: React.FC<Props> = (props) => {
  if (props.offset) {
    return (
      <div className={`${style.narrowWrapper} ${props.className ?? ''}`}>
        <div
          className={`${style.offsetWrapper} ${
            props.offset === WrapperOffsetSide.RIGHT
              ? style.right
              : props.offset === WrapperOffsetSide.FULL
                ? style.full
                : style.left
          } ${props.offsetClassName ?? ''}`}
        >
          <div
            className={`${style.offsetBackground}
              ${props.large ? style.large : ''}
            ${props.backgroundClassName ? props.backgroundClassName : ''}`}
          />
          <div
            className={`${style.narrowContent} ${props.contentClassName ?? ''}`}
          >
            {props.children}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={style.narrowWrapper}>
      <div className={`${style.narrowContent} ${props.contentClassName ?? ''}`}>
        {props.children}
      </div>
    </div>
  );
};
