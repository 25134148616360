import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TickCircle from '../../assets/icons/tick-circle.svg?react';
import WarningCircle from '../../assets/icons/warning-circle.svg?react';
import style from './style.module.less';
import { ToastIcon } from 'react-toastify/dist/types';

export const ToastWrapper = () => {
  const TickIcon: ToastIcon = (props) => {
    if(props.type === 'success') {
      return <TickCircle className={style.tickIcon} />;
    } else if(props.type === 'error') {
      return <WarningCircle className={style.tickIcon} />;
    } 
  };
  return (
    <ToastContainer
      position='bottom-center'
      autoClose={4000}
      hideProgressBar={true}
      newestOnTop={true}
      closeOnClick={true}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
      icon={TickIcon}
      closeButton={false}
      toastClassName={style.toast}
      bodyClassName={style.body}
      className={style.container}
      limit={3}
    />
  );
};
