import React, { useEffect, useState } from 'react';
import style from './style.module.less';
import InfoCircleIcon from '../../../assets/icons/info-circle.svg?react';
import { LawyerIntroModal } from './modal';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../redux/hooks';
import selectors from '../../../redux/selectors';
export const LawyerIntro = () => {
  const { id } = useParams<{ id: string }>();

  const [isOpen, setIsOpen] = useState(false);
  const openLawyerModal = () => setIsOpen(true);
  const closeLawyerModal = () => setIsOpen(false);

  const connection = useAppSelector(
    selectors.lawyerConnection.getConnectionById(id)
  );

  if(!connection?.lawyer) {
    return null;
  }

  return (
    <>
      <div className={style.container}>
        {/* <div className={style.prematterNameContainer}>
        <div className={style.iconContainer}>
          <FolderIcon className={style.icon} />
        </div>
        <div className={style.prematterName}>Vehicle Accident</div>
      </div> */}
        <img
          src={connection.lawyer.image}
          alt='Lawyer'
          className={style.lawyerImage}
        />
        <div className={style.lawyerNameContainer} onClick={openLawyerModal}>
          <div className={style.lawyerName}>
            {connection.lawyer.firstName}
            {connection.lawyer.firstName && connection.lawyer.lastName
              ? ' '
              : ''}
            {connection.lawyer.lastName}
          </div>
          <InfoCircleIcon className={style.warningIcon} />
        </div>
      </div>
      <LawyerIntroModal
        closeModal={closeLawyerModal}
        isOpen={isOpen}
        connection={connection}
      />
    </>
  );
};
