import React, {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { FileInputWrapper } from '../../../components/file-input';
import style from './style.module.less';
import ArrowUpIcon from '../../../assets/icons/arrow-up.svg?react';
import PlusIcon from '../../../assets/icons/plus.svg?react';
import { FileChatList } from '../../../components/file-chat-list';

interface Props {
  disabled?: boolean;
  message: string;
  files: File[];
  className?: string;
  onChangeInput: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onSend: () => void;
  setFiles: (files: File[]) => void;
}

export const ChatInputComponent = forwardRef<HTMLTextAreaElement, Props>(
  (props, ref) => {
    const {
      disabled,
      message,
      files,
      className,
      onChangeInput,
      onSend,
      setFiles,
    } = props;
    const { t } = useTranslation();

    const fileRef = useRef<HTMLInputElement>(null);

    const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
      const newFiles = e.target.files;
      if (newFiles) {
        setFiles([...files, ...Array.from(newFiles)]);
      }
    };

    const openFileSelector = useCallback(() => {
      fileRef.current?.click();
    }, [fileRef]);

    useEffect(() => {
      // we want to find the root component and add a on drag over event to it
      const root = document.getElementById('root');
      const onDragOver = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
      };

      const onDrop = (e: DragEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const newFiles = e.dataTransfer.files;
        if (newFiles) {
          setFiles([...Array.from(newFiles), ...files]);
        }
      };
      if (root) {
        root?.addEventListener('dragover', onDragOver);
        root?.addEventListener('drop', onDrop);
        return () => {
          root?.removeEventListener('dragover', onDragOver);
          root?.removeEventListener('drop', onDrop);
        };
      }
    }, [files, setFiles]);

    const removeFile = useCallback(
      (index: number) => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
      },
      [files, setFiles]
    );
    // On paste of a file into the ref textarea, add it to the files
    const onPaste = useCallback(
      (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
        const items = e.clipboardData?.items;
        if (items) {
          const newFiles = [];
          for (let i = 0; i < items.length; i++) {
            if (items[i].kind === 'file') {
              const file = items[i].getAsFile();
              if (file) {
                newFiles.push(file);
              }
            }
          }
          if (newFiles.length) {
            setFiles([...files, ...newFiles]);
            e.preventDefault();
          }
        }
      },
      [files, setFiles]
    );

    return (
      <>
        <div className={`${style.container} ${className ?? ''}`}>
          <div
            className={`${style.footerWrapper} ${
              files.length ? style.showFiles : ''
            }`}
          >
            <button
              className={style.fileButton}
              disabled={disabled}
              onClick={setFiles ? openFileSelector : undefined}
            >
              <FileInputWrapper onChangeHandler={onFileChange} ref={fileRef} />
              <PlusIcon className={style.plusIcon} />
            </button>
            <div className={style.chatInputWrapper}>
              <textarea
                ref={ref}
                placeholder={t('chat.input-placeholder')}
                className={style.chatInput}
                value={message}
                onChange={onChangeInput}
                disabled={disabled}
                onPaste={onPaste}
              />
            </div>
            <button
              onClick={onSend}
              className={style.sendButton}
              disabled={disabled}
            >
              <ArrowUpIcon className={style.arrowUpIcon} />
            </button>
            <FileChatList
              files={files}
              removeFile={removeFile}
              className={style.fileList}
            />
          </div>
        </div>
      </>
    );
  }
);
