import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { actions } from '../../redux/slices';
import selectors from '../../redux/selectors';
import { Header } from '../../components/header';
import { AccountHeader } from '../../components/account-header';
import { Footer } from '../../components/footer';
// import { LawyerImages } from '../../assets/lawyers';
import { Button } from '../../components/button';
import style from './style.module.less';
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg?react';
import SignOutIcon from '../../assets/icons/sign-out.svg?react';
import BinIcon from '../../assets/icons/bin.svg?react';
import DeleteAccountModal, {
  DeleteAccountModalRef,
} from '../../components/delete-account';
import { cleanKindePicture } from '../../utils/kinde';
import { useCookieHandler } from '../../utils/cookie';
import { SHOW_DISCLAIMER_COOKIE } from '../../constants';

export const EditAccount: React.FC = () => {
  const { logout } = useKindeAuth();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const deleteAccountRef = createRef<DeleteAccountModalRef>();
  const user = useAppSelector(selectors.user.getUser());
  const isUpdatingUser = useAppSelector(selectors.user.isUpdating());
  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const cookieHandler = useCookieHandler();
  const { t } = useTranslation();

  // get first name and last name from user

  const onBack = useCallback(() => {
    navigate('/account', { replace: true });
  }, [navigate]);

  const signOut = useCallback(() => {
    cookieHandler.deleteCookie(SHOW_DISCLAIMER_COOKIE);
    logout();
    dispatch(actions.session.delete());
  }, [logout, dispatch]);

  const onDeleteClick = useCallback(() => {
    deleteAccountRef.current?.openModal();
  }, [deleteAccountRef]);

  const resetPassword = useCallback(() => {
    // TODO: implement password reset
  }, []);
  const updateDisabled = useMemo(
    () =>
      isUpdatingUser ||
      ((!firstName || firstName === user?.firstName) &&
        (!lastName || lastName === user?.lastName)),
    [firstName, isUpdatingUser, lastName, user?.firstName, user?.lastName]
  );
  const save = useCallback(() => {
    // TODO: implement update user details
    if (!updateDisabled) {
      dispatch(actions.user.update({ firstName, lastName }));
    }
  }, [dispatch, firstName, updateDisabled, lastName]);

  useEffect(() => {
    if (user?.firstName) {
      setFirstName(user.firstName);
    }
    if (user?.lastName) {
      setLastName(user.lastName);
    }
  }, [user]);

  const onlyEmail: boolean = useMemo(() => {
    if (user?.identities.length === 1 && user?.identities[0] === 'email') {
      return true;
    } else {
      false;
    }
  }, [user?.identities]);

  return (
    <>
      <Header />
      <AccountHeader
        profilePicture={cleanKindePicture(user?.picture)}
        account={firstName}
        short={true}
      >
        <div className={style.headerContainer}>
          <div onClick={onBack} className={style.backContainer}>
            <ArrowLeftIcon className={style.backIcon} />
          </div>
        </div>
      </AccountHeader>
      <div className={style.container}>
        <div className={style.leftWrapperContainer} />
        <div className={style.innerContainer}>
          <div className={style.section}>
            <div className={style.innerSection}>
              <div className={style.title}>
                {t('account.edit-profile.details')}
              </div>
              <div className={style.inputGrid}>
                <label className={style.inputContainer}>
                  <div className={style.inputLabel}>
                    {t('account.edit-profile.first-name')}
                  </div>
                  <input
                    type='text'
                    className={style.input}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    disabled={isUpdatingUser}
                    readOnly={!onlyEmail}
                  />
                </label>
                <label className={style.inputContainer}>
                  <div className={style.inputLabel}>
                    {t('account.edit-profile.last-name')}
                  </div>
                  <input
                    type='text'
                    className={style.input}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    disabled={isUpdatingUser}
                    readOnly={!onlyEmail}
                  />
                </label>
                <label className={style.inputContainer}>
                  <div className={style.inputLabel}>
                    {t('account.edit-profile.email')}
                  </div>
                  <input
                    type='text'
                    className={style.input}
                    readOnly
                    value={user?.email}
                  />
                </label>
              </div>
              {onlyEmail && (
                <div className={style.actionButtonContainer}>
                  <button
                    className={style.resetButton}
                    onClick={save}
                    disabled={updateDisabled}
                  >
                    {t('confirm.save')}
                  </button>
                </div>
              )}
            </div>
            {/* <div className={style.innerSection}>
              <div className={style.title}>Password reset</div>
              <div className={style.passwordText}>
                A secure password reset process is available. Select the button
                below and an email will be sent to you. Follow the steps in the
                email to safely reset your password.
              </div>
              <div className={style.actionButtonContainer}>
                <button className={style.resetButton} onClick={resetPassword}>
                  {t('reset-password')}
                </button>
              </div>
            </div> */}
          </div>
          <div className={style.section}>
            <div className={style.title}>
              {t('account.edit-profile.delete-account')}
            </div>
            <div className={style.deleteContainer}>
              <div>
                {t('account.edit-profile.delete-account-description.0')}
                <br />
                {t('account.edit-profile.delete-account-description.1')}
              </div>
              <div className={style.actionButtonContainer}>
                <Button
                  label={t('confirm.delete')}
                  onClick={onDeleteClick}
                  className={style.deleteButton}
                  gap={20}
                  icon={<BinIcon className={style.deleteIcon} />}
                />
              </div>
            </div>
          </div>

          <div className={style.footer}>
            <div className={style.actionButtonContainer}>
              <Button
                label={t('account.sign-out')}
                icon={<SignOutIcon />}
                className={style.actionButton}
                gap={20}
                onClick={signOut}
              />
            </div>
          </div>
        </div>
      </div>
      <DeleteAccountModal ref={deleteAccountRef} />
      <Footer />
    </>
  );
};
