import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import style from './style.module.less';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import { BackHeader } from '../../components/back-header';
import confirmModal from '../../components/confirm-modal';
import { ContextItem } from '../../components/context-menu';
import { ChatWrapper } from './chat-wrapper';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { actions } from '../../redux/slices';
import selectors from '../../redux/selectors';
import { usePrevious } from '../../hooks/use-previous';
import DownloadIcon from '../../assets/icons/download.svg?react';
import BinIcon from '../../assets/icons/bin.svg?react';
import FolderIcon from '../../assets/icons/folder.svg?react';

const DELAY = 3 * 60 * 1000;

export const LawyerConnection: FC = () => {
  // if is the connection id
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const connection = useAppSelector(
    selectors.lawyerConnection.getConnectionById(id)
  );
  const connectionMessages = useAppSelector(
    selectors.lawyerConnection.getMessagesById(id)
  );
  const prevConnection = usePrevious(connection);
  const isSendingMessage = useAppSelector(
    selectors.lawyerConnection.isSendingMessage()
  );
  const prematter = useAppSelector(
    selectors.prematter.getById(connection?.prematterId)
  );
  const isFetchingMessages = useAppSelector(
    selectors.lawyerConnection.isFetchingMessages()
  );
  const prevIsFetchingMessages = usePrevious(isFetchingMessages);
  const fetchingMessageError = useAppSelector(
    selectors.lawyerConnection.getFetchMessagesError()
  );

  const onSendMessage = useCallback(
    (args: { text?: string; files?: File[] }) => {
      if (!isSendingMessage) {
        // TODO: if files we need to upload them first
        dispatch(
          actions.lawyerConnection.sendMessage({
            id,
            message: args.text,
          })
        );
      }
    },
    [dispatch, id, isSendingMessage]
  );

  const downloadChat = useCallback(() => {
    // open the link in a new tab
    // window.open(`/api/prematter/${id}/report/download?jwt=${token}`, '_blank');
  }, []);

  const deleteChat = useCallback(() => {
    // dispatch(actions.connection.deleteConnection(id));
  }, []);

  const backItems: ContextItem[] = useMemo(() => {
    return [
      {
        label: t('prematter.download'),
        onClick: () => downloadChat(),
        icon: <DownloadIcon />,
      },
      {
        label: t('connect.delete-conversation'),
        onClick: () =>
          confirmModal({
            title: t('connect.delete-conversation'),
            description: <>{t('delete-conversation-confirmation')}</>,
            action: {
              label: t('confirm.yes'),
              onClick: () => deleteChat(),
            },
            small: true,
          }),
        icon: <BinIcon />,
      },
    ];
  }, [deleteChat, downloadChat, t]);

  useEffect(() => {
    dispatch(actions.lawyerConnection.fetchById({ id }));
    dispatch(actions.prematter.fetch());
  }, [dispatch, id]);

  useEffect(() => {
    if (
      (connection && !prevConnection) ||
      connection?.id !== prevConnection?.id
    ) {
      dispatch(actions.lawyerConnection.fetchMessages({ id }));
      dispatch(
        actions.lawyerConnection.pollMessages({ connectionId: id, init: true })
      );
    }
  }, [connection, dispatch, id, prevConnection]);

  useEffect(() => {
    return () => {
      dispatch(actions.lawyerConnection.stopPollMessages({ connectionId: id }));
    };
  }, [dispatch, id]);

  useEffect(() => {
    // only when we have successfully fetched messages we want to update the read at
    if (
      !isFetchingMessages &&
      prevIsFetchingMessages &&
      !fetchingMessageError
    ) {
      dispatch(actions.lawyerConnection.updateReadAt({ id }));
    }
  }, [
    dispatch,
    id,
    isFetchingMessages,
    prevIsFetchingMessages,
    fetchingMessageError,
  ]);

  if (!connection) {
    return null;
  }

  return (
    <div className={style.container}>
      <Header />
      <div className={style.chatContainer}>
        <BackHeader items={backItems} className={style.prematterHeader}>
          <div className={style.caseNameWrapper}>
            <div className={style.iconBackground}>
              <FolderIcon className={style.icon} />
            </div>
            <div className={style.caseName}>{prematter?.name}</div>
          </div>
        </BackHeader>
        <ChatWrapper
          isLoading={false}
          messages={connectionMessages ?? []}
          onSendMessage={onSendMessage}
        />
      </div>
      <Footer className={style.anonFooter} />
    </div>
  );
};
