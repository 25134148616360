export const cleanKindePicture = (picture: any) => {
    if (picture && picture.startsWith('https://')) {
      return picture;
    } 
    try { return picture.data.url; } catch { }
    try {
      const json = JSON.parse(picture);
      return json.data.url;
    } catch {}
    return undefined;
  };
  