import React, {
  ChangeEvent,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import style from './style.module.less';
import { LegalCaseState, Prematter } from '@law-connect/types';
import { NavLink } from 'react-router-dom';
import PendingPrematter from '../../assets/icons/pending-prematter.svg?react';
import CompletePrematter from '../../assets/icons/complete-prematter.svg?react';
import PencilIcon from '../../assets/icons/pencil.svg?react';
import { useTranslation } from 'react-i18next';
import { ModalComponent } from '../modal';
import { SingularTextInput } from '../singular-text-input';
import { PrematterHeaderBar } from '../../containers/prematter/header';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { actions } from '../../redux/slices';
import selectors from '../../redux/selectors';
import { formatDate } from '../../utils/format-date';
import { canUseSettify } from '../../utils/can-use-settify';
import { isPrematterComplete } from '../../utils/is-prematter-complete';

const MAX_NAME_LENGTH = 75;

interface Props {
  prematter: Prematter;
  noExtension?: boolean;
  tabs?: boolean;
  preheaderBar?: boolean;
}

export const PrematterHeader: React.FC<Props> = (props) => {
  const {
    prematter,
    tabs = true,
    noExtension = false,
    preheaderBar = true,
  } = props;
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const isUpdatingName = useAppSelector(selectors.prematter.isUpdatePending());
  const [modalVisible, setModalVisible] = React.useState(false);
  const [name, setName] = React.useState(prematter.name ?? '');
  const messagesForPrematter = useAppSelector(
    selectors.lawyerConnection.getConnectionsByPrematterId(prematter.id)
  );

  const disabled = useMemo(
    () => isUpdatingName || !name.length || name === prematter.name,
    [isUpdatingName, name, prematter?.name]
  );

  const updateName = useCallback(() => {
    if (!disabled) {
      dispatch(
        actions.prematter.update({
          id: prematter.id,
          name,
        })
      );
    }
  }, [dispatch, disabled, name, prematter]);

  useEffect(() => {
    if (!isUpdatingName) {
      setModalVisible(false);
    }
  }, [isUpdatingName]);

  const onCancel = useCallback(() => {
    setName(prematter.name);
    setModalVisible(false);
  }, [prematter]);

  const onNameChange = useCallback((newName: string) => {
    if (newName.length <= MAX_NAME_LENGTH) {
      setName(newName);
    }
  }, []);

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onNameChange(e.target.value);
    },
    [onNameChange]
  );

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (e.key === 'Enter' && !disabled) {
        e.preventDefault();
        updateName();
      }
    },
    [disabled, updateName]
  );

  useEffect(() => {
    setName(prematter.name ?? '');
  }, [prematter.name]);

  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        {preheaderBar ? <PrematterHeaderBar prematter={prematter} /> : null}
        <div className={style.prematterCard}>
          <div className={style.content}>
            <div className={style.contentHeader}>
              <div className={style.iconWrapper}>
                {isPrematterComplete(prematter.state) ? (
                  <CompletePrematter className={style.folderIcon} />
                ) : (
                  <PendingPrematter className={style.folderIcon} />
                )}
              </div>
              <div className={style.titleWrapper}>
                <div className={style.nameWrapper}>
                  <div className={style.name}>{prematter.name}</div>
                  {tabs && (
                    <div
                      className={style.edit}
                      onClick={() => setModalVisible(true)}
                    >
                      <PencilIcon />
                    </div>
                  )}
                </div>
                <div className={style.date}>
                  {formatDate(prematter.createdAt)}
                </div>
              </div>
            </div>
            {tabs && (
              <div className={style.tabs}>
                <NavLink
                  to={`/case/${prematter.id}`}
                  end
                  className={({ isActive }) =>
                    `${style.tab} ${isActive ? style.active : ''}`
                  }
                  title={t('prematter.report')}
                >
                  {t('prematter.report')}
                </NavLink>
                <NavLink
                  to={`/case/${prematter.id}/history`}
                  end
                  className={({ isActive }) =>
                    `${style.tab} ${isActive ? style.active : ''}`
                  }
                  title={t('prematter.case-history')}
                >
                  {t('prematter.case-history')}
                </NavLink>
                <NavLink
                  to={`/case/${prematter.id}/info`}
                  end
                  className={({ isActive }) =>
                    `${style.tab} ${isActive ? style.active : ''}`
                  }
                  title={t('prematter.case-info')}
                >
                  {t('prematter.case-info')}
                </NavLink>
                {canUseSettify(prematter) && (
                  <NavLink
                    to={`/case/${prematter.id}/questionnaire`}
                    className={({ isActive }) =>
                      `${style.tab} ${isActive ? style.active : ''}`
                    }
                    title={'Questionnaire'}
                  >
                    Questionnaire
                  </NavLink>
                )}
                <NavLink
                  to={`/case/${prematter.id}/files`}
                  end
                  className={({ isActive }) =>
                    `${style.tab} ${isActive ? style.active : ''}`
                  }
                  title={t('prematter.case-files')}
                >
                  {t('prematter.case-files')}
                </NavLink>
                {messagesForPrematter?.length ? (
                  <div className={style.connectContainer}>
                    <NavLink
                      to={`/case/${prematter.id}/connect`}
                      end
                      className={({ isActive }) =>
                        `${style.tab} ${isActive ? style.active : ''}`
                      }
                      title={t('connect.title')}
                    >
                      {t('connect.title')}
                    </NavLink>
                    <div className={style.newMessages}>
                      {messagesForPrematter.length}
                    </div>
                  </div>
                ) : null}
              </div>
            )}
          </div>
          <div
            className={`${style.background} ${
              noExtension ? style.noExtension : ''
            }`}
          />
        </div>

        {modalVisible && (
          <ModalComponent
            title={t('prematter.case-name')}
            footer={
              <div className={style.footer}>
                <button onClick={onCancel} className={style.footerButton}>
                  <div className={style.label}>{t('confirm.cancel')}</div>
                </button>
                <button
                  onClick={updateName}
                  disabled={disabled}
                  className={style.footerButton}
                >
                  <div className={style.label}>{t('confirm.save')}</div>
                </button>
              </div>
            }
            onClose={onCancel}
          >
            <div className={style.nameChangeModal}>
              <SingularTextInput
                value={name}
                onChange={onInputChange}
                clear={() => setName('')}
                onKeyDown={onKeyDown}
              />
            </div>
          </ModalComponent>
        )}
      </div>
    </div>
  );
};
