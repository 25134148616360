import React, { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import selectors from '../../../redux/selectors';
import Chat, { ChatRef } from '../../chat';
import { actions } from '../../../redux/slices';
import BinIcon from '../../../assets/icons/bin.svg?react';
import { useTranslation } from 'react-i18next';
import { confirmModal } from '../../../components/confirm-modal';
import { ChatWrapperComponent } from '../../../components/chat-wrapper';

// IMPORTANT: the delete case redirect is handled by the prematter/index
interface Props {}
export const PrematterChat: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const prematter = useAppSelector(selectors.prematter.getById(id));
  const sessionPrematter = useAppSelector(selectors.session.getPrematter());
  const chatHistoryRef = useRef<ChatRef>();
  const isDeletingSession = useAppSelector(
    selectors.session.isDeleteSessionPending()
  );

  // On load, scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (prematter && sessionPrematter && prematter.id !== sessionPrematter.id) {
      dispatch(actions.session.setPrematter(prematter));
    }
  }, [dispatch, prematter, sessionPrematter]);

  // on unmount, delete session
  useEffect(() => {
    return () => {
      dispatch(actions.session.delete());
    };
  }, []);

  const deleteChat = () => {
    dispatch(actions.session.delete());
    dispatch(actions.prematter.delete({ id }));
  };

  const getBounding = useCallback(() => {
    return chatHistoryRef.current?.getChatBoundingClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatHistoryRef.current]);

  useEffect(() => {
    // on load if prematterId exists we also fetch files
    if (prematter?.id) {
      dispatch(actions.file.fetchByPrematterId({ prematterId: prematter?.id }));
    }
  }, [prematter?.id, dispatch]);

  if (!prematter) {
    return null;
  }

  return (
    <>
      <ChatWrapperComponent
        getBounding={getBounding}
        isLoading={isDeletingSession}
        chatHistoryRef={chatHistoryRef}
        isInChat={false}
        backItems={[
          {
            label: t('confirm.delete'),
            onClick: () =>
              confirmModal({
                title: t('confirm.delete'),
                description: <>{t('account.delete-case-confirmation')}</>,
                action: {
                  label: t('confirm.yes'),
                  onClick: () => deleteChat?.(),
                },
                small: true,
              }),
            icon: <BinIcon />,
          },
        ]}
      />
    </>
  );
};
