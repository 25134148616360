import React from 'react';
import { Header } from '../../../components/header';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import selectors from '../../../redux/selectors';
import { PrematterHeader } from '../../../components/prematter-header';
import {
  NarrowWrapper,
  WrapperOffsetSide,
} from '../../../components/narrow-wrapper';
import { NoPaddingContentTitle } from '../../../components/content-title';
import { useTranslation } from 'react-i18next';
import { FileWithUpload, Question } from '@law-connect/types';
import style from './style.module.less';
import { Footer } from '../../../components/footer';
import { actions } from '../../../redux/slices';
import { VerificationBanner } from '../verification-banner';
import { OverviewForm } from '@law-connect/react-components';

interface Props {}
export const PrematterInfo: React.FC<Props> = () => {
  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const prematter = useAppSelector(selectors.prematter.getById(id));

  const files = useAppSelector(selectors.file.getPrematterFiles(id));

  const onFinish = (questions: Question[]) => {
    dispatch(actions.prematter.regenerate({
      id,
      form: {
        ...prematter.context.form,
        questions,
      }
    }));
  };

  const handleUpload = async (files: FileWithUpload[]) => {
    files.forEach((file) => {
      dispatch(actions.file.upload({
        prematterId: id,
        file,
      }));
    });
  };

  if (!prematter) {
    return null;
  }

  return (
    <>
      <Header />
      <PrematterHeader prematter={prematter} />
      <div className={style.wrapper}>
        <NarrowWrapper
          offset={WrapperOffsetSide.LEFT}
          offsetClassName={style.tabletWidth}
        >
          <VerificationBanner prematter={prematter} />
          <NoPaddingContentTitle
            title={t('prematter.case-info')}
            className={style.title}
          />
          <OverviewForm
            form={prematter.context.form}
            files={files}
            onSubmit={onFinish}
            onSubmitLabel='Regenerate answers'
            onFileUpload={handleUpload}
            onFileRemove={(file) => {
              dispatch(actions.file.delete({
                id: file.id,
                prematterId: id,
              }));
            }}
          />
        </NarrowWrapper>
      </div>
      <Footer />
    </>
  );
};
