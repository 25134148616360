import { useEffect, useState } from 'react';

export const useKeyboardHeight = (minKeyboardHeight = 100) => {
  const [mobileKeyboardHeight, setMobileKeyboardHeight] = useState<
    null | number
  >(null);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  // we want to only scroll when we stop scrolling

  useEffect(() => {
    const resize = (e: Event) => {
      const h = e.target as VisualViewport;
      const keyboardHeight = window.innerHeight - h.height;
      if (keyboardHeight) {
        setMobileKeyboardHeight(keyboardHeight);
      } else {
        setMobileKeyboardHeight(null);
      }
    };

    visualViewport.addEventListener('resize', resize);
    return () => {
      visualViewport.removeEventListener('resize', resize);
    };
  }, []);

  // check if window inner height changes
  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    isKeyboardOpen:
      minKeyboardHeight && mobileKeyboardHeight > minKeyboardHeight,
    mobileKeyboardHeight,
    windowHeight,
  };
};
