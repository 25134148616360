import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorHandlerComponent } from '../../components/error-handler';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import selectors from '../../redux/selectors';
import { actions } from '../../redux/slices';

const ErrorHandler: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sendMessageError = useAppSelector(
    selectors.websocket.sendMessageError()
  );
  const connectWebsocketError = useAppSelector(
    selectors.websocket.connectError()
  );
  const generalSessionError = useAppSelector(
    selectors.session.getGeneralError()
  );

  const showError: boolean = useMemo(() => {
    return (
      (sendMessageError || connectWebsocketError || generalSessionError) != null
    );
  }, [connectWebsocketError, generalSessionError, sendMessageError]);

  const retryAction = useCallback(() => {
    if (connectWebsocketError) {
      dispatch(actions.websocket.connect({}));
    } else if (sendMessageError || generalSessionError) {
      dispatch(actions.session.fetch());
    }
    dispatch(actions.websocket.clearErrors());
  }, [connectWebsocketError, dispatch, sendMessageError, generalSessionError]);

  return (
    <ErrorHandlerComponent
      showError={showError}
      action={{ text: t('chat.retry'), onClick: retryAction }}
    />
  );
};

export default ErrorHandler;
