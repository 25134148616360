import React from 'react';
import { LegalCaseState } from '@law-connect/types';
// eslint-disable-next-line @stylistic/js/max-len
import ProcessingPrematter from '../../assets/icons/processing-prematter.svg?react';
import CompletePrematter from '../../assets/icons/complete-prematter.svg?react';
import { ProcessingSpinner } from '../../components/processing-spinner';
import PendingPrematter from '../../assets/icons/pending-prematter.svg?react';
import style from './style.module.less';
import { isPrematterComplete } from '../../utils/is-prematter-complete';

export const getIcon = (state: LegalCaseState) => {
  if(state === LegalCaseState.PreparingReport) {
    return (
      <div className={style.processingWrapper}>
        <div className={style.processingWrapper}>
          <div className={style.processingSpinner}>
            <ProcessingSpinner />
          </div>
          <ProcessingPrematter className={style.processingIcon} />
        </div>
      </div>
    );
  } else if (isPrematterComplete(state, true)) {
    return <CompletePrematter />;
  } else {
    return <PendingPrematter />;
  }
};
