import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import selectors from '../../../redux/selectors';
import { Header } from '../../../components/header';
import { PrematterHeader } from '../../../components/prematter-header';
import {
  NarrowWrapper,
  WrapperOffsetSide,
} from '../../../components/narrow-wrapper';
import { NoPaddingContentTitle } from '../../../components/content-title';
import style from './style.module.less';
import { Footer } from '../../../components/footer';
import { actions } from '../../../redux/slices';
import { Table } from '../../../components/table';
import { TableRow } from '../../../components/table/row';
import { formatDate } from '../../../utils/format-date';
import { Button } from '../../../components/button';
import { ContextMenu } from '../../../components/context-menu';
import { HorizontalRule } from '../../../components/horizontal-rule';
import env from '../../../constants/env';
import DownloadIcon from '../../../assets/icons/download.svg?react';
import BinIcon from '../../../assets/icons/bin.svg?react';
import { FileIcon } from '../../../components/file-icon';
import { EmailLinkComponent } from './email-link';
import { UploadComponent } from './upload';
import PadlockClosedIcon from '../../../assets/icons/padlock-closed.svg?react';
import { VerificationBanner } from '../verification-banner';

const FETCH_TIMEOUT = 60000; // 1 minute delay between refetching the files

interface Props {}
export const PrematterFiles: React.FC<Props> = () => {
  const { id = '' } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const prematter = useAppSelector(selectors.prematter.getById(id));
  const allFiles = useAppSelector(selectors.file.getAll());
  const fetchTimer = useRef<number>();

  const files = useMemo(
    () => allFiles.filter((f) => f?.prematterIds?.includes(id)),
    [allFiles, id]
  );
  const fetchPending = useAppSelector(
    selectors.file.isFetchByPrematterIdPending(id)
  );
  const fetchError = useAppSelector(
    selectors.file.hasFetchByPrematterIdError(id)
  );

  const fetchNewFiles = useCallback(() => {
    if (!fetchPending && !fetchError && id) {
      dispatch(actions.file.fetchByPrematterId({ prematterId: id }));
    }
  }, [dispatch, fetchError, fetchPending, id]);

  useEffect(() => {
    fetchNewFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchTimer.current) {
      clearInterval(fetchTimer.current);
    }
    fetchTimer.current = window.setInterval(fetchNewFiles, FETCH_TIMEOUT);
  }, [fetchNewFiles]);

  const downloadFile = (fileId: string) => {
    dispatch(actions.file.download({ id: fileId }));
  };

  const deleteFile = (fileId: string) => {
    const file = allFiles.find((f) => f.id === fileId);
    dispatch(
      actions.file.delete({ id: fileId, prematterId: id, fileName: file?.name })
    );
  };

  if (!prematter) {
    return null;
  }

  return (
    <>
      <Header />
      <PrematterHeader prematter={prematter} />
      <div className={style.wrapper}>
        <NarrowWrapper
          offset={WrapperOffsetSide.LEFT}
          offsetClassName={style.tabletWidth}
        >
          <VerificationBanner prematter={prematter} />
          <NoPaddingContentTitle
            title={t('prematter.case-files')}
            className={style.title}
          />
          <div className={style.bodyWrapper}>
            <EmailLinkComponent id={id} />
            <UploadComponent id={id} prematterState={prematter?.state} />
            <HorizontalRule />
          </div>
          {files?.length ? (
            <Table>
              {(files || []).map((file) => {
                return (
                  <TableRow
                    key={file.id}
                    className={`${style.tableRow} ${
                      file.attachedToMessage ? style.faded : ''
                    }`}
                    items={[
                      <FileIcon file={file} className={style.fileIcon} />,
                      <div className={style.fileTableCell}>
                        <div className={style.fileTableName} onClick={() => {}}>
                          {file.name}
                        </div>
                        <div className={style.fileTableTimestamp}>
                          {formatDate(file.createdAt)}
                        </div>
                      </div>,
                      file?.url?.path ? (
                        <a
                          href={file?.url?.path}
                          target={'_blank'}
                          className={style.linkLabel}
                        >
                          <Button
                            label={t('account.view')}
                            className={style.viewButton}
                            labelClassName={style.viewButtonLabel}
                            onClick={() => {}}
                          />
                        </a>
                      ) : (
                        <Button
                          label={t('account.view')}
                          className={style.viewButton}
                          onClick={() => downloadFile(file.id)}
                          labelClassName={style.viewButtonLabel}
                        />
                      ),
                      file.attachedToMessage ? (
                        <PadlockClosedIcon className={style.padlockIcon} />
                      ) : (
                        <ContextMenu
                          className={style.menuIcon}
                          items={[
                            {
                              label: t('prematter.download'),
                              icon: <DownloadIcon />,
                              onClick: () => downloadFile(file.id),
                            },
                            {
                              label: t('prematter.delete'),
                              icon: <BinIcon />,
                              onClick: () => deleteFile(file.id),
                            },
                          ]}
                        />
                      ),
                    ]}
                  />
                );
              })}
            </Table>
          ) : (
            <div className={style.noFilesContainer}>
              <div className={style.title}>{t('prematter.no-files')}</div>
              <div className={style.description}>
                {t('prematter.no-files-description')}
              </div>
              <img
                src={`${env.PUBLIC_URL}/no-files-bg.png`}
                className={style.noFileImage}
              />
            </div>
          )}
        </NarrowWrapper>
      </div>
      {/* TODO: Use inline on mobile */}
      {/* <InlineForm prematter={prematter} onFinish={onFinish} /> */}
      <Footer />
    </>
  );
};
