import React from 'react';
import style from './style.module.less';
import { useOnClickOutside } from 'usehooks-ts';
import ReactDOM from 'react-dom';
import CloseIcon from '../../assets/icons/cross-circle.svg?react';
import { Root, createRoot } from 'react-dom/client';
import useStopScroll from '../../hooks/use-stop-scroll';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
  title: string;
  children: React.ReactNode;
  onClose?: () => void;
  footer?: React.ReactNode;
}

export const ModalComponent: React.FC<Props> = (props) => {
  const { title, children, onClose, footer } = props;
  const promptRef = React.useRef(null);
  useStopScroll(true);

  useOnClickOutside(promptRef, (e) => {
    e.preventDefault();
    onClose ? onClose() : null;
  });

  return (
    <div className={style.modal} style={{ opacity: 1 }}>
      <div className={style.content} ref={promptRef}>
        <div className={style.header}>
          <div className={style.title}>{title}</div>
          {onClose && (
            <div className={style.closeIcon} onClick={onClose}>
              <CloseIcon />
            </div>
          )}
        </div>
        <div className={style.body}>{children}</div>
        {footer && <div className={style.footer}>{footer}</div>}
      </div>
    </div>
  );
};

interface ModalProps {
  targetId?: string;
  title: string;
  body: React.ReactNode;
  onClose?: () => void;
}

export const modal = (props: ModalProps) => {
  const { title, body, onClose } = props;
  const targetId = 'modal';
  let divTarget = document.getElementById(props.targetId || targetId);
  let root: Root | null = null;

  const closeModal = () => {
    if (divTarget) {
      ReactDOM.unmountComponentAtNode(divTarget);
      divTarget.remove();
      onClose ? onClose() : null;
    }
  };

  if (!divTarget) {
    divTarget = document.createElement('div');
    divTarget.id = props.targetId || targetId;
    document.body.appendChild(divTarget);
  }

  if (!root) {
    root = createRoot(divTarget);
  }

  root.render(
    <ModalComponent title={title} onClose={closeModal}>
      {body}
    </ModalComponent>
  );
};
