import React, {
  CSSProperties,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import selectors from '../../redux/selectors';
import { actions } from '../../redux/slices';
import style from './style.module.less';
import {
  LegalCaseState,
  Message,
  Prematter,
  WebSocketMessage,
  WebSocketMessageType,
} from '@law-connect/types';

import AuthModal, {
  AuthModalType,
  LoginModalRef,
} from '../../components/auth-modal';
import { ChatHistory } from '../../components/chat-history';
import { useKeyboardHeight } from './use-keyboard-height';

export interface ChatRef {
  getChatBoundingClient: () => DOMRect;
}

interface Props {
  scrollToEnd: () => void;
}

// we want to expose the div height to the parent component and to do that we use forwardRef
const Chat = forwardRef<ChatRef | null, Props>((props, ref) => {

  const { scrollToEnd } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const botAuthModalRef = useRef<LoginModalRef | null>(null);
  const chatHistoryRef = useRef<HTMLDivElement>(null);
  const websocketPrematter = useAppSelector(selectors.session.getPrematter());
  const prematter: Prematter = {
    ...websocketPrematter,
    messages:
      websocketPrematter?.messages?.map(
        (m) =>
          ({
            ...m,
            id: m.id || uuid(),
            from:
              m.from === 'system'
                ? 'lawyer'
                : m.from === 'user'
                  ? 'client'
                  : m.from,
            content: (m as Message).content || (m as WebSocketMessage).message,
          } as Message)
      ) || [],
  };
  const chatState = useAppSelector(selectors.session.getChatState());
  const isWsConnected = useAppSelector(selectors.websocket.isSetUp());
  const isWsConecting = useAppSelector(selectors.websocket.isConnecting());
  const isPendingForm = useAppSelector(selectors.session.isGetFormPending());
  const isAddQuestionPending = useAppSelector(
    selectors.session.isAddQuestionPending()
  );
  const isSessionPending = useAppSelector(selectors.session.isSessionPending());
  const isSendingWebsocketMessage = useAppSelector(
    selectors.websocket.isSendingMessage()
  );
  const addQuestionError = useAppSelector(
    selectors.session.getAddQuestionError()
  );
  const chatError = useAppSelector(selectors.session.getChatError());
  const uploadFileError = useAppSelector(selectors.file.getUploadError());
  const isFetchingSession = useAppSelector(
    selectors.session.isFetchSessionPending()
  );
  const [messageError, setMessageError] = useState<string | null>(null);

  const { windowHeight } = useKeyboardHeight();
  const messagesLength = useMemo(
    () => websocketPrematter?.messages?.length ?? 0,
    [websocketPrematter?.messages]
  );
  const isUploadPendingList = useAppSelector(selectors.file.isUploadPending());
  const isFileUploading = useMemo(
    () => Object.values(isUploadPendingList ?? {}).some((v) => v),
    [isUploadPendingList]
  );

  useImperativeHandle(
    ref,
    () => ({
      getChatBoundingClient: () =>
        chatHistoryRef.current?.getBoundingClientRect(),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chatHistoryRef.current]
  );

  const checkWsConnection = useCallback(async () => {
    if (!isWsConnected) {
      if (!isWsConecting) {
        dispatch(
          actions.websocket.connect({ prematterId: websocketPrematter?.id })
        );
      }
      // wait 0.5 sec for websocket to be connected
      await new Promise((resolve) => setTimeout(resolve, 500));
    }
  }, [isWsConnected, isWsConecting, dispatch, websocketPrematter]);

  const sendMessage = useCallback(
    async (data: WebSocketMessage) => {
      if (
        chatState === LegalCaseState.Location ||
        chatState === LegalCaseState.MatterTypes ||
        !chatState
      ) {
        await checkWsConnection();
        dispatch(actions.websocket.sendMessage(data));
      }
    },
    [chatState, checkWsConnection, dispatch]
  );

  const onLocationSuccess = useCallback(
    (position: GeolocationPosition) => {
      // TODO: we should use a data or payload key instead of message
      sendMessage({
        id: uuid(),
        type: WebSocketMessageType.Location,
        from: 'user',
        message: JSON.stringify([
          position.coords.latitude,
          position.coords.longitude,
        ]),
        timestamp: Date.now(),
      });
      setMessageError(null);
      dispatch(actions.session.setLocationPending(false));
    },
    [dispatch, sendMessage]
  );

  const onLocationError = useCallback(
    (error: GeolocationPositionError) => {
      console.error('Error getting location', error);
      setMessageError(t('chat.geoloc-error'));
      dispatch(actions.session.setLocationPending(false));
    },
    [dispatch, t]
  );

  const openLocation = useCallback(() => {
    dispatch(actions.session.setLocationPending(true));
    navigator.geolocation.getCurrentPosition(
      onLocationSuccess,
      onLocationError
    );
  }, [dispatch, onLocationSuccess, onLocationError]);

  const addQuestion = useCallback(
    (args: { question: string }) => {
      dispatch(actions.session.addQuestion(args));
    },
    [dispatch]
  );
  const deleteQuestion = useCallback(
    (args: { questionId: string }) => {
      dispatch(actions.session.deleteQuestion(args));
    },
    [dispatch]
  );

  const onBotAuthClose = useCallback(() => {
    dispatch(actions.session.reset());
    dispatch(actions.websocket.deleteSession());
  }, [dispatch]);

  // TODO: Review server bot detection
  // useEffect(() => {
  //   if (
  //     prematter &&
  //     prematter.messages.length > 0 &&
  //     prematter.messages.find((m) => m.type === MessageType.BotDetected)
  //   ) {
  //     botAuthModalRef.current?.openModal();
  //   }
  // }, [prematter]);

  

  // we watnt to scroll to end on init, when sending a message or when chat height changes
  useEffect(() => {
    if (!isFetchingSession) {
      scrollToEnd();
    }
  }, [scrollToEnd, isFetchingSession]);

  useEffect(() => {
    if (isSendingWebsocketMessage || isSessionPending) {
      scrollToEnd();
    }
  }, [isSendingWebsocketMessage, isSessionPending, scrollToEnd]);

  useEffect(() => {
    if (messagesLength > 0) {
      scrollToEnd();
    }
  }, [messagesLength, scrollToEnd]);

  return (
    <>
      <div
        className={style.chatHeight}
        style={
          {
            '--viewport': `${windowHeight}px`,
          } as CSSProperties
        }
        ref={chatHistoryRef}
        onResize={scrollToEnd}
      >
        <div className={style.container}>
          <div className={style.endRefContainer}>
            <ChatHistory
              prematter={prematter}
              openLocation={openLocation}
              checkWsConnection={checkWsConnection}
              addQuestion={addQuestion}
              deleteQuestion={deleteQuestion}
              onConfirm={() => dispatch(actions.session.getForm())}
              sendConfirmation={(args) =>
                dispatch(actions.websocket.sendConfirmation(args))
              }

              messageError={chatError || messageError || uploadFileError}
              addQuestionError={addQuestionError}
              filesLoading={isFileUploading}
              addQuestionPending={isAddQuestionPending}
              submitFormPending={isPendingForm}
            />
          </div>
        </div>
      </div>
      <AuthModal
        ref={botAuthModalRef}
        type={AuthModalType.BOT_AUTH}
        onClose={onBotAuthClose}
      />
    </>
  );
});

export default Chat;
