import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import style from './style.module.less';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import deleteBg from '../../assets/delete-account.png';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '../../assets/icons/cross-circle.svg?react';
import { actions } from '../../redux/slices';
import selectors from '../../redux/selectors';
import { usePrevious } from '../../hooks/use-previous';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';

export interface DeleteAccountModalRef {
  closeModal: () => void;
  openModal: () => void;
}

const DeleteAccountModal = forwardRef<DeleteAccountModalRef | null, unknown>(
  (_, ref) => {
    const { logout } = useKindeAuth();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const modalRef = createRef<HTMLDivElement>();
    const closeModal = useCallback(() => setOpen(false), []);
    const openModal = useCallback(() => setOpen(true), []);
    const isDeletingAccount = useAppSelector(selectors.user.isDeleting());
    const prevIsDeletingAccount = usePrevious(isDeletingAccount);
    const deleteError = useAppSelector(selectors.user.getDeleteError());
    const navigate = useNavigate();

    useOnClickOutside(modalRef, closeModal);

    useImperativeHandle(ref, () => ({
      closeModal,
      openModal,
    }));

    useEffect(() => {
      // stop scrolling when modal is open
      if (open) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }, [open]);

    const deleteAccount = useCallback(() => {
      // have an api to delete an account!!!
      if (!isDeletingAccount) {
        dispatch(actions.session.delete());
        dispatch(actions.user.delete());
      }
    }, [dispatch, isDeletingAccount]);

    // if finishing deleting and no error then we want to redirect back to the account main page
    useEffect(() => {
      if (prevIsDeletingAccount && !isDeletingAccount && !deleteError) {
        logout();
        navigate('/', { replace: true });
      }
    }, [
      logout,
      deleteError,
      isDeletingAccount,
      navigate,
      prevIsDeletingAccount,
    ]);

    const body = useMemo(
      () => (
        <div className={style.modalWrapper}>
          <div className={style.modalBody} ref={modalRef}>
            <div className={style.headerWrapper}>
              <img src={deleteBg} className={style.bgImage} />
              <div className={style.closeIcon} onClick={closeModal}>
                <CloseIcon />
              </div>
              <div className={style.header}>
                <div className={style.title}>
                  {t('account.delete-modal.title')}
                </div>
                <div className={style.bodyText}>
                  {t('account.delete-modal.description.0')}
                  <br />
                  {t('account.delete-modal.description.1')}
                </div>
              </div>
            </div>
            <div className={style.buttonWrapper}>
              <button className={style.deleteButton} onClick={deleteAccount}>
                {t('account.delete-modal.title')}
              </button>
              <button className={style.button} onClick={closeModal}>
                {t('confirm.cancel')}
              </button>
            </div>
          </div>
        </div>
      ),
      []
    );

    return createPortal(open ? body : <></>, document.body);
  }
);

export default DeleteAccountModal;
