import React, { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utils/format-date';
import style from './style.module.less';
import { ContextMenu } from '../context-menu';
import confirmModal from '../confirm-modal';
import ArrowRightIcon from '../../assets/icons/arrow-right.svg?react';
import BinIcon from '../../assets/icons/bin.svg?react';
import StopIcon from '../../assets/icons/stop.svg?react';
import { Button } from '../button';
import { MessageRow } from '@law-connect/react-components';

interface Props {
  id: string;
  name: string;
  prematterName: string;
  timestamp: number;
  isNew?: boolean;
  image?: string;
}

export const MessageRowComponent: FC<Props> = (props) => {
  const { id, image, name, prematterName, isNew, timestamp } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToMessage = useCallback(() => {
    navigate(`/lawyer-connection/${id}`);
  }, [id, navigate]);

  const deleteConverstaion = useCallback(() => {
    console.log('delete conversation');
  }, []);

  const endChat = useCallback(() => {
    console.log('end conversation');
  }, []);
  
  return (
    <MessageRow
      description={prematterName}
      title={name}
      isNew={isNew}
      timestamp={timestamp}
      Image={
        image ? (
          <img src={image} alt={name} className={style.lawyerImage} />
        ) : (
          <div className={style.lawyerPlaceholder}>
            {name?.length ? name[0] : '?'}
          </div>
        )
      }
      button={{
        label: t('account.view'),
        onClick: goToMessage,
        icon: <ArrowRightIcon />,
      }}
      items={[
        {
          label: t('account.view'),
          icon: <ArrowRightIcon />,
          onClick: goToMessage,
        },
        {
          label: t('connect.end-chat'),
          icon: <StopIcon />,
          onClick: () => endChat(),
        },
        {
          label: t('prematter.delete'),
          icon: <BinIcon />,
          onClick: () =>
            confirmModal({
              title: t('connect.delete-conversation'),
              description: (
                <>{t('connect.delete-converstation-confirmation')}</>
              ),
              action: {
                label: t('confirm.yes'),
                onClick: deleteConverstaion,
              },
              small: true,
            }),
        },
      ]}
    />
  );
};
