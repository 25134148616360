import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { actions } from '../../redux/slices';
import selectors from '../../redux/selectors';
import { Wrapper } from '../../components/wrapper';
import { Header } from '../../components/header';
import { useNavigate } from 'react-router-dom';
import { HorizontalRule } from '../../components/horizontal-rule';
import PendingPrematter from '../../assets/icons/pending-prematter.svg?react';

import SignOutIcon from '../../assets/icons/sign-out.svg?react';
import { ContentTitle } from '../../components/content-title';
import { Button } from '../../components/button';
import { AccountHeader } from '../../components/account-header';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { Footer } from '../../components/footer';
import style from './style.module.less';
import { LegalCaseState, Prematter } from '@law-connect/types';
import { Table } from '../../components/table';
import { TableRow } from '../../components/table/row';
import { formatDate } from '../../utils/format-date';
import BinIcon from '../../assets/icons/bin.svg?react';
import { useTranslation } from 'react-i18next';
import { ContextMenu } from '../../components/context-menu';
import ArrowRight from '../../assets/icons/arrow-right.svg?react';
import SettingsCogIcon from '../../assets/icons/settings-cog.svg?react';
import ConfirmModal, {
  ConfirmModalModalRef,
} from '../../components/confirm-modal';
import { WidthType, useScreenSize } from '../../hooks/use-is-mobile';
import { cleanKindePicture } from '../../utils/kinde';
import { RecentCardsComponent } from './recent-cards';
import { getIcon } from './get-icon';
import { getTitle } from './use-get-title';
import { useCookieHandler } from '../../utils/cookie';
import { SHOW_DISCLAIMER_COOKIE } from '../../constants';
import DownloadIcon from '../../assets/icons/download.svg?react';
import { useDownloadReport } from '../../components/use-download-report';
import { Helmet } from 'react-helmet';
import { isPrematterComplete } from '../../utils/is-prematter-complete';
import { AccountMessages } from '../../components/lawyer-messages';

export const Account: React.FC = () => {
  const { logout } = useKindeAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const user = useAppSelector(selectors.user.getUser());
  const recent = useAppSelector(selectors.prematter.getRecent());
  const prematters = useAppSelector(selectors.prematter.getAll());
  const sortedPrematters = useMemo(
    () => [...prematters].sort((a, b) => a.createdAt - b.createdAt),
    [prematters]
  );
  const { t } = useTranslation();
  const confirmModalRef = useRef<ConfirmModalModalRef | null>(null);
  const [prematterToDelete, setPrematterToDelete] = useState<string | null>(
    null
  );
  const screenType = useScreenSize();
  const cookieHandler = useCookieHandler();
  const getDownloadReportHeader = useDownloadReport();

  const signOut = () => {
    cookieHandler.deleteCookie(SHOW_DISCLAIMER_COOKIE);
    logout();
    dispatch(actions.session.delete());
  };

  const getTitleWrapper = useCallback(
    (prematter: Prematter) => {
      return getTitle(t, prematter);
    },
    [t]
  );

  const openSettings = useCallback(() => {
    navigate('/account/edit');
  }, [navigate]);

  useEffect(() => {
    dispatch(actions.prematter.fetch());
  }, [dispatch]);

  const deletePrematter = useCallback(() => {
    if (prematterToDelete) {
      dispatch(actions.prematter.delete({ id: prematterToDelete }));
      setPrematterToDelete(null);
    }
  }, [dispatch, prematterToDelete]);

  const openConfirmationDelete = useCallback((id: string) => {
    setPrematterToDelete(id);
    confirmModalRef.current?.openModal();
  }, []);

  useEffect(() => {
    dispatch(actions.lawyerConnection.fetchAll());
  },[dispatch]);

  return (
    <>
      <Helmet>
        <title>{t('pages.account')}</title>
      </Helmet>
      <Header />
      <AccountHeader
        profilePicture={cleanKindePicture(user?.picture)}
        account={user?.firstName}
      />
      <Wrapper>
        <div className={style.section}>
          <ContentTitle
            title={t('account.recent-cases')}
            className={`${style.contentTitle} ${style.innerCard}`}
          />
          <RecentCardsComponent prematters={recent} />
        </div>
        <AccountMessages />
        <div className={style.section}>
          <ContentTitle
            title={t('account.all-cases')}
            className={style.contentTitle}
          />
          <Table >
            {prematters.length === 0 && (
              <div className={style.noCasesTableRow}>
                <PendingPrematter />
                <div className={style.noCasesLabel}>
                  {t('account.no-cases')}
                </div>
              </div>
            )}
            {sortedPrematters?.map((prematter) => {
              const action = () =>
                isPrematterComplete(prematter.state)
                  ? navigate(`/case/${prematter.id}`)
                  : navigate(`/case/${prematter.id}/chat`);

              const menuItems = [
                screenType !== WidthType.Mobile
                  ? {
                    label: isPrematterComplete(prematter.state)
                      ? t('account.view')
                      : t('account.edit'),
                    onClick: () => {
                      isPrematterComplete(prematter.state)
                        ? navigate(`/case/${prematter.id}`)
                        : navigate(`/case/${prematter.id}/chat`);
                    },
                    icon: <ArrowRight />,
                  }
                  : null,
                isPrematterComplete(prematter.state, true)
                  ? getDownloadReportHeader(prematter.id)
                  : null,
                {
                  label: t('confirm.delete'),
                  onClick: () => openConfirmationDelete(prematter.id),
                  icon: <BinIcon />,
                },
              ].filter((i) => i);

              return (
                <TableRow
                  key={prematter.id}
                  className={style.caseTableRow}
                  items={[
                    getIcon(prematter.state),
                    <div className={style.prematterTableCell}>
                      <div
                        className={style.prematterTableName}
                        onClick={action}
                      >
                        {getTitleWrapper(prematter)}
                      </div>
                      <div className={style.prematterTableTimestamp}>
                        {formatDate(prematter.updatedAt)}
                      </div>
                    </div>,
                    <Button
                      label={
                        isPrematterComplete(prematter.state)
                          ? t('account.view')
                          : t('account.edit')
                      }
                      className={style.viewButton}
                      labelClassName={style.buttonLabel}
                      onClick={action}
                    />,
                    <ContextMenu items={menuItems} />,
                  ]}
                />
              );
            })}
          </Table>
        </div>
        <HorizontalRule />
        <div className={style.actions}>
          <Button
            label={t('account.settings')}
            icon={<SettingsCogIcon className={style.settingsIcon} />}
            gap={20}
            onClick={openSettings}
            className={`${style.button} ${style.settingsButton}`}
          />
          <Button
            label={t('account.sign-out')}
            icon={<SignOutIcon />}
            gap={20}
            onClick={signOut}
            className={style.button}
          />
        </div>
      </Wrapper>
      <Footer />
      <ConfirmModal
        ref={confirmModalRef}
        title={t('confirm.delete')}
        description={<>{t('account.delete-case-confirmation')}</>}
        action={{
          label: t('confirm.yes'),
          onClick: deletePrematter,
        }}
        small={true}
      />
    </>
  );
};
