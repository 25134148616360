import React, { useEffect } from 'react';
import style from './style.module.less';
import WarningCircleIcon from '../../assets/icons/warning-circle.svg?react';

interface Props {
  resetSession: () => void;
  refreshSession: () => void;
  expiresIn?: number;
  disableChat: () => void;
}
export const SessionExpiryWarning: React.FC<Props> = (props) => {
  const { resetSession, refreshSession, expiresIn } = props;
  const [timeLeft, setTimeLeft] = React.useState(expiresIn || 300);

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [props, timeLeft]);

  useEffect(() => {
    if (timeLeft <= 0) {
      props.disableChat();
    }
  }, [props, timeLeft]);

  if (timeLeft <= 0) {
    return (
      <div className={style.sessionExpiryWrapper}>
        <div className={style.sessionExpiry}>
          <div className={style.notification}>
            <WarningCircleIcon />
            <div className={style.text}>
              <div className={style.expired}>
                Your chat session has expired.{' '}
              </div>
              <div><span onClick={resetSession}>Reset</span>.</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={style.sessionExpiryWrapper}>
      <div className={style.sessionExpiry}>
        <div className={style.notification}>
          <WarningCircleIcon />
          <div className={style.text}>
            <div className={style.countdown}>
              Session will expire in {Math.floor(timeLeft / 60)} m{' '}
              {Math.floor(timeLeft % 60)} s{' '}
            </div>
            <div><span onClick={refreshSession}>Renew Now</span>.</div>
          </div>
        </div>
      </div>
    </div>
  );
};
