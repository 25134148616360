import React, {
  ChangeEvent,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import style from './style.module.less';
import { usePrevious } from '../../hooks/use-previous';
import { useTranslation } from 'react-i18next';
import { Button } from '../button';
import QuestionComponent from './question';
import PlusCircleSvg from '../../assets/icons/plus-circle.svg?react';
import { MessageType, Prematter } from '@law-connect/types';
import ChatMessage from '../chat-history/message';
import { FAQAddQuestion } from '../faq-add-question';
import { MAX_ALLOWED_QUESTION } from '../../constants';

interface Props {
  prematter: Prematter;
  hideChatMessage?: boolean;
  readOnly?: boolean;
  addQuestion: (args: { question: string }) => void;
  deleteQuestion: (args: { questionId: string }) => void;
  onConfirm?: () => void;
  error?: string;
  children?: ReactNode;

  addQuestionPending: boolean;
  submitFormPending: boolean;
}

const FAQComponent: FC<Props> = (props) => {
  const {
    prematter,
    hideChatMessage = false,
    addQuestion,
    deleteQuestion,
    onConfirm,
    readOnly,
    error,
    children,
    addQuestionPending,
    submitFormPending,
  } = props;

  const [hasTyped, setHasTyped] = useState(true);
  const questions = useMemo(
    () => prematter.context.questions || [],
    [prematter.context.questions]
  );
  const [showAddQuestion, setShowAddQuestion] = useState(false);
  const [newQuestion, setNewQuestion] = useState('');
  const { t } = useTranslation();
  const faqActive = true;

  const answered = false; // questions?.every((q) => q.answer !== null);
  const hasSomeAnswers = questions?.some((q) => q.answer !== null);
  const prevSubmitPending = usePrevious(submitFormPending);
  const prevAddQuestionPending = usePrevious(addQuestionPending);
  const maxQuestions = useMemo(
    () => questions?.length >= MAX_ALLOWED_QUESTION,
    [questions]
  );
  const pending = useMemo(
    () => addQuestionPending || submitFormPending,
    [addQuestionPending, submitFormPending]
  );

  const onConfirmHandler = useCallback(() => {
    if (!readOnly && faqActive && !pending && onConfirm) {
      // call api to getForm
      onConfirm();
    }
  }, [readOnly, faqActive, onConfirm, pending]);

  const deleteQuestionHandler = useCallback(
    (index: number) => {
      if (!readOnly && faqActive && !pending) {
        const questionToDelete = questions[index];
        deleteQuestion({ questionId: questionToDelete.id });
      }
    },
    [readOnly, faqActive, deleteQuestion, questions, pending]
  );
  const showAddQuestionComponent = useCallback(() => {
    if (!readOnly && !pending) {
      setShowAddQuestion(true);
    }
  }, [readOnly, pending]);

  const onTextChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (!readOnly) {
        setNewQuestion(e.target.value);
        setHasTyped(true);
      }
    },
    [readOnly]
  );

  const addQuestionHandler = useCallback(() => {
    if (
      !readOnly &&
      newQuestion.length &&
      !pending &&
      questions.length < MAX_ALLOWED_QUESTION
    ) {
      if (faqActive) {
        addQuestion({ question: newQuestion });
        setHasTyped(false);
      }
    } else {
      setShowAddQuestion(false);
    }
  }, [
    readOnly,
    newQuestion,
    pending,
    questions.length,
    faqActive,
    addQuestion,
  ]);

  // after adding question and no error
  useEffect(() => {
    if (!addQuestionPending && prevAddQuestionPending && !error) {
      setShowAddQuestion(false);
      setNewQuestion('');
    }
  }, [addQuestionPending, prevAddQuestionPending, error]);

  // after submitting the form we want to close the add question component
  useEffect(() => {
    if (submitFormPending && !prevSubmitPending) {
      setShowAddQuestion(false);
      setNewQuestion('');
    }
  }, [submitFormPending, prevSubmitPending]);

  return (
    <>
      <div
        className={`${
          answered ? style.answeredContainer : style.questionContainer
        }
        ${hasSomeAnswers ? style.noShadow : ''}`}
      >
        {questions.map((q, i) => (
          <QuestionComponent
            key={q.id || i}
            question={q}
            answered={answered}
            deleteQuestionHandler={() => deleteQuestionHandler(i)}
            readOnly={readOnly}
          />
        ))}
        <FAQAddQuestion
          disabled={hasTyped}
          pending={pending}
          question={newQuestion}
          show={showAddQuestion}
          onChange={onTextChange}
          submit={addQuestionHandler}
          error={error}
          errorClassName={style.error}
          className={style.addQuestionContainer}
        />
        {!readOnly && !answered && questions?.length > 0 ? (
          !maxQuestions ? (
            <div
              className={`${style.question} ${style.addQuestion} ${
                maxQuestions ? style.disabled : ''
              }`}
              onClick={showAddQuestionComponent}
            >
              <div className={style.questionText}>
                {t('chat.add-own-question')}
              </div>
              <button className={style.iconContainer}>
                <PlusCircleSvg className={style.icon} />
              </button>
            </div>
          ) : (
            <div className={`${style.question} ${style.maxQuestion}`}>
              {t('prematter.max-questions')}
            </div>
          )
        ) : null}
      </div>
      {children}
      {!hideChatMessage && !readOnly && !answered && !pending && (
        <div className={style.chatActionContainer}>
          <Button
            onClick={onConfirmHandler}
            label={t('confirm.confirm')}
            className={style.chatButton}
            disabled={pending}
          />
        </div>
      )}
      {!readOnly && !answered && pending && (
        <ChatMessage
          chatState={prematter?.state}
          chat={{
            id: 'confirm.confirm',
            content: t('confirm.confirm'),
            from: 'client',
            type: MessageType.Confirmation,
            createdAt:
              prematter.messages[prematter.messages.length - 1].createdAt,
          }}
          prematter={prematter}
        />
      )}
    </>
  );
};

export default FAQComponent;
