import React, {
  FC,
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import style from './style.module.less';
import { Footer } from '../../components/footer';
import LoadingSvg from '../../assets/icons/loading.svg?react';
import { Header } from '../header';
import { BackHeader } from '../back-header';
import { ContextItem } from '../context-menu';
import { GOOGLE_PRIVACY_POLICY, GOOGLE_TERMS } from '../../constants';
import { useTranslation } from 'react-i18next';
import ChatboxComponent from '../../containers/chat/chatbox';
import { WidthType, useScreenSize } from '../../hooks/use-is-mobile';
import Chat, { ChatRef } from '../../containers/chat/';
import { COOKIE_ID } from '../cookie-consent';

interface Props {
  isInChat: boolean;
  backItems: ContextItem[];
  isLoading: boolean;
  getBounding: () => DOMRect;
  message?: string;
  chatDisabled?: boolean;
  chatHistoryRef: React.RefObject<ChatRef>;
}

export const ChatWrapperComponent: FC<Props> = (props) => {
  const {
    backItems,
    isLoading,
    chatDisabled,
    getBounding,
    message,
    isInChat,
    chatHistoryRef,
  } = props;
  const chatBodyRef = createRef<HTMLDivElement>();
  const endRef = useRef<HTMLDivElement>();
  const { t } = useTranslation();
  const screenType = useScreenSize();

  const toScrollBlock = useMemo(() => {
    if (screenType !== WidthType.Mobile || !isInChat) {
      return 'end';
    } else {
      return 'start';
    }
  }, [isInChat, screenType]);

  const scrollToEnd = useCallback(() => {
    // on mobile scroll to start while on desktop scroll to end
    if (endRef.current) {
      endRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: toScrollBlock,
        inline: 'nearest',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toScrollBlock, endRef?.current]);

  const privacyText: string = useMemo(() => {
    return t('auth.google-privacy-terms')
      .replace(/GOOGLE_TERMS/g, GOOGLE_TERMS)
      .replace(/GOOGLE_PRIVACY_POLICY/g, GOOGLE_PRIVACY_POLICY);
  }, [t]);
  // if cookie is open we want to make the end ref height === to the cookie height
  const calcResize = () => {
    const cookieConsent = document.getElementById(COOKIE_ID);
    if (cookieConsent?.getBoundingClientRect()?.height && endRef.current) {
      endRef.current.style.height = `${
        cookieConsent.getBoundingClientRect().height
      }px`;
    } else if (endRef.current){
      endRef.current.style.height = '0px';
    }
  };

  useEffect(() => {
    calcResize();
    window.addEventListener('resize', calcResize);
    window.addEventListener('scroll', calcResize);
    return () => {
      window.removeEventListener('resize', calcResize);
      window.removeEventListener('scroll', calcResize);
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <div className={style.loadingContainer}>
          <LoadingSvg className={style.loadingIcon} />
        </div>
      ) : (
        <div className={style.container}>
          <div className={style.body} ref={chatBodyRef}>
            <Header />
            <div className={style.chatWrapper}>
              <BackHeader items={backItems} />
              <Chat ref={chatHistoryRef} scrollToEnd={scrollToEnd} />
            </div>
          </div>
          <ChatboxComponent
            initialMessage={message}
            getChatBoundingClient={getBounding}
            disabled={chatDisabled}
            chatBodyRef={chatBodyRef}
            scrollToEnd={scrollToEnd}
          />
          <div ref={endRef} className={style.endRef} />
        </div>
      )}
      <div
        className={style.recaptcha}
        dangerouslySetInnerHTML={{ __html: privacyText }}
      />

      <Footer className={style.anonFooter} />
    </>
  );
};
