import React, { useMemo } from 'react';
import { File } from '@law-connect/types';
import style from './style.module.less';
import nl2br from 'react-br';
import { FileIcon } from '../file-icon';

interface Props {
  file: File;
}

export const ChatFile = (props: Props) => {
  const { file } = props;

  const preview = useMemo(() => {
    if (file.mime.startsWith('image/') && file.url) {
      return (
        <div className={style.fileImageContainer}>
          <img
            src={file.url.path}
            alt={file.name}
            className={style.fileImage}
          />
        </div>
      );
    } else if (file.textContent) {
      return <div className={style.textContent}>{nl2br(file.textContent)}</div>;
    } else {
      return null;
    }
  }, [file]);
  if (!file) {
    return null;
  }
  return (
    <div className={style.fileContainer}>
      <div
        className={`${style.fileTextContainer} ${!preview ? style.full : ''}`}
      >
        <FileIcon file={file} className={style.icon} />
        <div className={style.fileName}>{file.name}</div>
      </div>
      {preview}
    </div>
  );
};
