import React from 'react';
import { useTranslation } from 'react-i18next';
import { Wrapper } from '../../components/wrapper';
import { Header } from '../../components/header';
import { Footer } from '../../components/footer';
import style from './style.module.less';
import { AboutCardComponent } from '../../components/about-card';
import { Helmet } from 'react-helmet';

interface Props {}
export const About: React.FC<Props> = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet><title>{t('pages.about')}</title></Helmet>
      <Header />
      <Wrapper className={style.container} contentClassName={style.content}>
        <div className={style.heroCard}>
          <div className={style.titleWrapper}>
            <div className={style.title}>{t('about.title')}</div>
          </div>
          <img
            src={'/about-hero.png'}
            alt='scale'
            className={style.heroImage}
          />
          <div className={style.background} />
        </div>
        <div>
          <AboutCardComponent
            iconTitle={t('about.mission')}
            title={t('about.mission-title')}
            descriptions={[{ context: t('about.mission-description') }]}
            image={'/about-brief.png'}
          />
          <AboutCardComponent
            iconTitle={t('about.how')}
            title={t('about.how-title')}
            descriptions={[
              {
                title: t('about.accessibility'),
                context: t('about.accessibility-description'),
              },
              {
                title: t('about.affordability'),
                context: t('about.affordability-description'),
              },
              {
                title: t('about.empowerment'),
                context: t('about.empowerment-description'),
              },
            ]}
            right={true}
            image={'/about-hammer.png'}
          />
          <AboutCardComponent
            iconTitle={t('about.values')}
            title={t('about.values-title')}
            descriptions={[
              {
                title: t('about.innovation'),
                context: t('about.innovation-description'),
              },
              {
                title: t('about.quality'),
                context: t('about.quality-description'),
              },
              {
                title: t('about.curiosity'),
                context: t('about.curiosity-description'),
              },
            ]}
            right={false}
            image={'/about-paper.png'}
          />
        </div>
      </Wrapper>
      <Footer />
    </>
  );
};
