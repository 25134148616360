import React, { FC } from 'react';
import { File as LawFile } from '@law-connect/types';
import PdfIcon from '../../assets/icons/files/pdf.svg?react';
import JpgIcon from '../../assets/icons/files/jpg.svg?react';
import DocIcon from '../../assets/icons/files/doc.svg?react';
import GenericIcon from '../../assets/icons/files/generic-file.svg?react';
import PngIcon from '../../assets/icons/files/png.svg?react';
import RtfIcon from '../../assets/icons/files/rtf.svg?react';
import TxtIcon from '../../assets/icons/files/txt.svg?react';

interface Props {
  file: File | LawFile;
  className?: string;
}

export const FileIcon: FC<Props> = (props) => {
  const { file, className } = props;
  let matchString = '';
  if ((file as LawFile)?.id) {
    const lawFile = file as LawFile;
    matchString = lawFile.mime;
  } else {
    const f = props.file as File;
    matchString = f.type;
  }

  switch (matchString) {
    case 'application/pdf':
      return <PdfIcon className={className ?? ''} />;
    case 'image/jpeg':
      return <JpgIcon className={className ?? ''} />;
    case 'image/png':
      return <PngIcon className={className ?? ''} />;
    case 'application/msword':
      return <DocIcon className={className ?? ''} />;
    case 'application/rtf':
      return <RtfIcon className={className ?? ''} />;
    case 'text/plain':
      return <TxtIcon className={className ?? ''} />;
    default:
      return <GenericIcon className={className ?? ''}/>;
  }
};
